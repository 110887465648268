<template>
  <v-pagination
  class="pagination"
    data-cy-element="pagination-bar"
    :color="color"
    @input="onChangePage()"
    v-model="selectedPage"
    :length="pageCount"
    :circle="circleType"
    :total-visible="totalVisible"
  ></v-pagination>
</template>


<script>
export default {
  name: "PaginationBar",
  data: () => ({
    page: null,
  }),
  props: {
    currentPage: Number,
    pageCount: Number,
    circleType: Boolean,
    totalVisible: Number,
    color: String,
  },
  computed: {
    selectedPage: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.page = value;
      },
    },
  },
  methods: {
    onChangePage() {
      if (this.page !== this.selectedPage) {
        this.$emit("onChangePage", this.page);
      }
    },
  },
};
</script>