<template>
  <v-expansion-panel :focusable="focusable">
    <v-expansion-panel-header class="grey lighten-3">{{
      title
    }}</v-expansion-panel-header>
    <v-expansion-panel-content>
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>


<script>
export default {
  name: "ExpansionPanel",
  props: {
    title: String,
    focusable: Boolean,
  },
};
</script>