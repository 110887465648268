var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"product-card pa-8",attrs:{"data-cy-element":"product-card","data-cy-product-id":_vm.productData.id},on:{"click":function($event){_vm.$route.path === _vm.productProxy(_vm.productData.id)
      ? false
      : _vm.routeToProduct(_vm.productProxy(_vm.productData.id))}}},[_c('v-row',[(
        _vm.productData.attributes.pic &&
        _vm.productData.attributes.pic.width !== '0' &&
        _vm.productData.attributes.pic.height !== 0
      )?_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","lg":"5"}},[_c('img',{attrs:{"width":_vm.productData.attributes.pic.width / 2.5,"src":_vm.imageProxy(_vm.productData.attributes.pic.url)}})]):_vm._e(),(!_vm.productData.attributes.pic && _vm.productData.attributes.high_pic)?_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","lg":"5"}},[_c('img',{attrs:{"width":_vm.productData.attributes.high_pic.width / 2.5,"src":_vm.imageProxy(_vm.productData.attributes.high_pic.url)}})]):_vm._e(),(
        (_vm.productData.attributes.pic &&
          _vm.productData.attributes.pic.width === '0') ||
        (_vm.productData.attributes.high_pic &&
          _vm.productData.attributes.high_pic.width === '0')
      )?_c('v-col',{staticClass:"d-flex align-center justify-center grey--text",attrs:{"cols":"12","lg":"5"}},[_vm._v(" Kein Bild vorhanden ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"7"}},[_c('div',{staticClass:"text-h6 mb-4"},[_vm._v(_vm._s(_vm.productData.attributes.title))]),_c('div',{staticClass:"mb-4"},[_vm._v("HYVE-ID: "+_vm._s(_vm.productData.id))]),(_vm.productData.attributes.released_at)?_c('div',{staticClass:"mb-4"},[_vm._v(" Erscheinungsdatum: "+_vm._s(_vm.dateProxy(_vm.productData.attributes.released_at))+" ")]):_vm._e(),(_vm.productData.attributes.long_description)?[_c('div',{staticClass:"text-subtitle-1 mb-4"},[_vm._v("Spezifikationen")]),_c('p',[_vm._v(_vm._s(_vm.productData.attributes.long_description))])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }