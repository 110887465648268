const state = {
  loading: false,
  message: {
    title: "",
    text: "",
    colorType: "",
  }
};

export default state;
