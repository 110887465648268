import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0064af",
        secondary: "#157CC1"
      },
    },
    icons: {
      iconfont: "mdi",
      values: {
        login: "mdi-account",
      },
    },
  },
});
