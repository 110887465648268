import CategoryService from "@/api/category.service";
import mainCategories from "@/config/main-categories";

const actions = {
  fetchCategories: ({ dispatch, commit, state }) => {
    dispatch("globalStore/isLoading", [], { root: true });
    return new Promise((resolve, reject) => {
      if (mainCategories.length !== 0 && state.mainCategory !== 9889) {
        commit("UPDATE_CATEGORIES", mainCategories);
        resolve(mainCategories);
      } else {
        CategoryService.index({
          "filter[import_active]": state.mainCategory === 9889 ? undefined : 1, // Bike
          "filter[parent_id]":
            state.mainCategory === 9889 ? 8100000000000 : undefined, // Bike
        })
          .then((res) => {
            // Bike
            if (state.mainCategory === 9889) {
              commit("UPDATE_SUB_CATEGORIES", res.data);
              resolve();
            } else {
              commit("UPDATE_CATEGORIES", res.data);
              resolve(res);
            }
          })
          .catch((error) => {
            commit("UPDATE_CATEGORIES", []);
            reject(error);
            dispatch("globalStore/isNotLoading", [], { root: true });
          });
      }
    });
  },
  fetchSuppliers: ({ dispatch, commit }, id) => {
    dispatch("globalStore/isLoading", [], { root: true });
    return new Promise((resolve, reject) => {
      CategoryService.show(id)
        .then((res) => {
          commit("UPDATE_SUPPLIERS", res.data.relationships.suppliers.data);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
          dispatch("globalStore/isNotLoading", [], { root: true });
        });
    });
  },
  setSuppliers: ({ commit }, id) => {
    commit("UPDATE_SUPPLIERS", id);
  },
  setMainCategory: ({ commit }, id) => {
    commit("UPDATE_MAIN_CATEGORY", id);
  },
  selectMainCategory: ({ dispatch, commit, state }, id) => {
    const oldMainCategory = state.mainCategory;
    const checkInitAction = (mainCategoryId) => {
      if (oldMainCategory !== mainCategoryId || oldMainCategory === null) {
        dispatch("productStore/setSelectedFeatures", [], { root: true });
        dispatch("productStore/setCurrentPage", 1, { root: true });
        dispatch("productStore/setSearchTerm", "" , { root: true });
        dispatch("productStore/setProducts", [], { root: true });
        dispatch("productStore/setFeatures", [], { root: true });
        commit("UPDATE_SUB_CATEGORY", null);
        commit("UPDATE_SUPPLIER", null);
        //commit("UPDATE_SUPPLIER", supplierId);
      }
    };

    dispatch("globalStore/isLoading", [], { root: true });
    commit("UPDATE_MAIN_CATEGORY", id);
    if (state.mainCategory !== 9889) {
      dispatch("fetchSuppliers", id).then(() => {
        checkInitAction(id);
        dispatch("productStore/fetchProducts", null, { root: true });
      });
    } else { // Bike
      dispatch("productStore/setSelectedFeatures", [], { root: true });
      dispatch("fetchCategories").then(() => {
        checkInitAction(id);
        dispatch("globalStore/isNotLoading", [], { root: true });
      });
    }
  },
  selectSubCategory: ({ dispatch, commit }, id) => {
    commit("UPDATE_SUB_CATEGORY", id);
    if (id !== null) {
      dispatch("fetchSuppliers", id).then(() => {
        //dispatch("selectSupplier", res.data.relationships.suppliers.data[0].id);
        dispatch("productStore/fetchProducts", null, { root: true });
      });
    }
  },
  selectSupplier: ({ dispatch, commit, state }, id) => {
    if (state.supplier !== id || state.supplier === null) {
      dispatch("productStore/setSelectedFeatures", [], { root: true });
      dispatch("productStore/setCurrentPage", 1, { root: true });
    }
    dispatch("productStore/setSearchTerm", "");
    commit("UPDATE_SUPPLIER", id);
    dispatch("productStore/fetchProducts", null, { root: true });
  },
  setSupplier: ({ commit }, id) => {
    commit("UPDATE_SUPPLIER", id);
  },
};

export default actions;
