<template>
  <v-row>
    <v-col
      cols="6"
      md="12"
      lg="6"
      v-for="feature in this.returnAllBestItemsFromItems()"
      :key="`${Math.random()}-id-${itemId}-value-${
        feature.presentation_value
      }-${Math.random()}`"
    >
      <v-checkbox
        data-cy-element="feature-checkbox"
        :data-cy-feature-name="feature.presentation_value"
        v-model="activeCheckBoxes"
        :value="{
          id: itemId,
          value: feature.value,
          name: feature.presentation_value,
        }"
        :id="`${Math.random()}-id-${itemId}-value-${
          feature.presentation_value
        }-${Math.random()}`"
      >
        <template v-slot:label>
          {{ feature.presentation_value }}
          <span class="product-count">{{ feature.product_count }}</span>
        </template>
      </v-checkbox>
    </v-col>
    <v-col cols="12" v-if="items.length > 10">
      <v-autocomplete
        v-if="items.length > 10"
        v-model="activeCheckBoxes"
        item-text="presentation_value"
        item-value="value"
        label="Filter Suche"
        :items="this.returnAllItemsWithoutBestItems()"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "FeatureList",
  props: {
    items: Array,
    activeItems: Array,
    itemId: Number,
  },
  computed: {
    activeCheckBoxes: {
      get() {
        return this.activeItems;
      },
      set(value) {
        if (!Array.isArray(value)) {
          const item = this.returnItemFromItems(value);
          const feature = {
            id: this.itemId,
            value: item[0].value,
            name: item[0].presentation_value,
          };
          if (
            this.activeCheckBoxes.length > 0 &&
            this.checkItemInActiveItems(feature).length === 0
          ) {
            this.activeCheckBoxes.push(feature);
            value = this.activeCheckBoxes;
          } else {
            value = [feature];
          }
        }
        this.$emit("onChangeCheckBoxes", value || []);
      },
    },
  },
  methods: {
    returnItemFromItems(searchItem) {
      return this.items.filter((item) => item.value === searchItem);
    },
    checkItemInActiveItems(searchItem) {
      return this.activeCheckBoxes.filter((item) => item === searchItem);
    },
    returnAllBestItemsFromItems() {
      return this.items.length > 10
        ? this.items.filter((item, i) => i < 4)
        : this.items;
    },
    returnAllItemsWithoutBestItems() {
      return this.items.length > 10
        ? this.items.filter((item, i) => i > 4)
        : this.items;
    },
  },
};
</script>