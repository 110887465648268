import ApiService from "@/api/api.service";

const UserService = {
  getCurrentUser() {
    return ApiService.get("api/v1/user");
  },
  getUser(id) {
    return ApiService.get(`api/v1/user/${id}`);
  },
  getAllUsers() {
    return ApiService.get("api/v1/users");
  },
  updateUser(updateUser) {
    return ApiService.update("api/v1/users", updateUser.id, {
      name: updateUser.name,
      email: updateUser.email,
      password: updateUser.password,
      password_confirmation: updateUser.passwordConfirmation,
      is_admin: updateUser.isAdmin === true ? '1' : '0',
    });
  },
  createUser(newUser) {
    return ApiService.post("api/v1/users", {
      name: newUser.name,
      email: newUser.email,
      password: newUser.password,
      password_confirmation: newUser.passwordConfirmation,
      is_admin: newUser.isAdmin === true ? '1' : '0',
    });
  },
  deleteUser(deleteUser) {
    return ApiService.delete("api/v1/users", deleteUser.id);
  },
};

export default UserService;
