<template>
  <div class="page__container">
    <Navigation :user="user" @onSubmitLogout="signOutUser()" />
    <v-main class="mt-4 mb-10">
      <v-container fluid class="pa-0">
        <v-container class="max-screen-large px-4 px-lg-0">
          <router-view></router-view>
        </v-container>
      </v-container>
    </v-main>
    <FooterBar :color="'primary'" class="mt-12" />
  </div>
</template>

<script>
import FooterBar from "@/components/atome/FooterBar.vue";
import Navigation from "@/components/molecules/NavigationBar.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "MainView",
  components: {
    Navigation,
    FooterBar,
  },
  computed: {
    ...mapGetters({
      user: "authStore/user",
    }),
  },
  methods: {
    ...mapActions({
      logout: "authStore/logout",
    }),
    signOutUser() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
