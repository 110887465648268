<template>
  <v-footer :color="color" padless>
    <v-row no-gutters>
      <v-col class="py-4 text-center white--text d-flex justify-center align-center" cols="12">
        <img :src="logoUrl" width="24" alt="logo" class="mr-2" />
        <strong>
          HYVE THINGS -
          {{ new Date().getFullYear() }}</strong
        >
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterBar",
  props: {
    text: String,
    color: String,
  },
  data: () => ({
    logoUrl: `${process.env.VUE_APP_BASE_URL}/hyve-logo-only-white.svg`,
  }),
};
</script>
