<template>
  <v-row data-cy-page="dashboard-page">
    <v-col class="mt-12 mb-6" cols="12">
      <SearchBar
        v-if="categories.length > 0"
        :categories="categories"
        :category="mainCategory"
        :suppliers="suppliers"
        :supplier="supplier"
        :subCategories="subCategories"
        :subCategory="subCategory"
        :searchTerm="searchTerm"
        @onSubmitSearch="searchProduct"
        @onSubmitEANSearch="searchProductbyEan"
        @onSubmitIdSearch="searchProductByHyveId"
        @onChangeCategorySelection="selectCategory"
        @onChangeSupplierSelection="selectSupplier"
        @onChangeSubCategorySelection="selectSubCategory"
      />
    </v-col>
    <v-container>
      <v-row>
        <v-divider class="py-2"></v-divider>
        <v-col cols="12" md="4">
          <v-col cols="12">
            <TopicHeader
              :divider="true"
              :text="'Filter'"
              :color="'white'"
              :bgColor="'primary'"
              :icon="'mdi-filter-variant'"
            />
          </v-col>
          <v-col cols="12" class="pb-0">
            <v-select
              v-model="currentProductCountFilter"
              item-text="name"
              item-value="value"
              label="Filter Anzahl"
              :items="productCountFilterList"
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="pt-0">
            <ExpansionPanelAccordion :focusable="true" :multiple="false">
              <ExpansionPanel
                data-cy-element="expansion-panel"
                :data-cy-expansion-panel-title="item.name"
                v-for="(item, i) in features"
                :key="`${i}-${item.id}-${item.name}`"
                :focusable="true"
                :title="item.name"
              >
                <FeatureList
                  @onChangeCheckBoxes="selectFeature"
                  :activeItems="selectedFeatures"
                  :items="item.values"
                  :itemId="item.id"
                />
              </ExpansionPanel>
            </ExpansionPanelAccordion>
          </v-col>
        </v-col>
        <v-col cols="12" md="8">
          <v-col cols="12">
            <TopicHeader
              :divider="true"
              :text="'Produkte'"
              :color="'white'"
              :bgColor="'primary'"
              :icon="'mdi-format-list-bulleted-square'"
            >
              <PaginationBar
                @onChangePage="selectPage"
                :currentPage="currentPage"
                :pageCount="pageCount"
                :totalVisible="0"
                :circleType="false"
              />
            </TopicHeader>
          </v-col>
          <v-col cols="12" v-if="selectedFeatures.length > 0">
            <BubbleTagList
              v-for="feature in selectedFeatures"
              :key="`id-${Math.random}-${feature.id}-name-${feature.name}`"
              :item="feature"
              :items="selectedFeatures"
              :name="feature.name.toString()"
              :close="true"
              @onClickClose="selectFeature"
            />
          </v-col>
          <v-col :class="selectedFeatures.length > 0 ? '' : 'pt-10 mt-10'" cols="12">
            <ProductCardList :products="products" />
          </v-col>
          <v-col class="d-flex justify-end" cols="12">
            <PaginationBar
            class="mr-3"
              :color="'secondary'"
              @onChangePage="selectPage"
              :currentPage="currentPage"
              :pageCount="pageCount"
              :totalVisible="0"
              :circleType="false"
            />
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ExpansionPanelAccordion from "@/components/atome/ExpansionPanelAccordion.vue";
import ExpansionPanel from "@/components/atome/ExpansionPanel.vue";
import FeatureList from "@/components/atome/FeatureList.vue";
import PaginationBar from "@/components/atome/PaginationBar.vue";
import TopicHeader from "@/components/atome/TopicHeader.vue";

import SearchBar from "@/components/molecules/SearchBar.vue";
import BubbleTagList from "@/components/molecules/BubbleTagList.vue";
import ProductCardList from "@/components/molecules/ProductCardList.vue";

//import VuexHistoryStore from "@/plugins/vuexHistoryStore/vuexHistoryStore.js";

export default {
  name: "DashboardView",
  data: () => ({
    productCountFilterList: [5, 15, 30],
    restorationState: false,
  }),
  components: {
    SearchBar,
    ExpansionPanelAccordion,
    ExpansionPanel,
    ProductCardList,
    FeatureList,
    PaginationBar,
    BubbleTagList,
    TopicHeader,
  },
  computed: {
    ...mapGetters({
      categories: "categoryStore/categories",
      suppliers: "categoryStore/suppliers",
      supplier: "categoryStore/supplier",
      mainCategory: "categoryStore/mainCategory",
      subCategories: "categoryStore/subCategories",
      subCategory: "categoryStore/subCategory",
      products: "productStore/products",
      features: "productStore/features",
      searchTerm: "productStore/searchTerm",
      selectedFeatures: "productStore/selectedFeatures",
      currentPage: "productStore/currentPage",
      pageCount: "productStore/pageCount",
      productCount: "productStore/productCount",
    }),
    currentProductCountFilter: {
      get() {
        return this.productCount;
      },
      set(value) {
        this.selectProductCount(value);
      },
    },
    restorationInProcess: {
      get() {
        return this.restorationState;
      },
      set(value) {
        this.restorationState = value;
      },
    },
  },
  methods: {
    ...mapActions({
      selectMainCategory: "categoryStore/selectMainCategory",
      selectSupplier: "categoryStore/selectSupplier",
      selectSubCategory: "categoryStore/selectSubCategory",
      getCategories: "categoryStore/fetchCategories",
      searchProduct: "productStore/searchProduct",
      searchProductbyEan: "productStore/searchProductByEAN",
      searchProductByHyveId: "productStore/searchProductByHyveId",
      setSearchTerm: "productStore/setSearchTerm",
      selectFeature: "productStore/selectFeature",
      selectPage: "productStore/selectPage",
      selectProductCount: "productStore/selectProductCount",
      restoreHistoryState: "globalStore/restoreHistoryState",
      appIsNotLoading: "globalStore/isNotLoading",
    }),
    selectCategory(value) {
      if (!this.restorationInProcess) {
        this.selectMainCategory(value);
      }
    },
  },
  mounted() {
    // History Store Feature
    // if (VuexHistoryStore.containStates()) {
    //   this.restorationInProcess = true;
    //   this.restoreHistoryState().then(() => {
    //     this.restorationInProcess = false;
    //     setTimeout(() => {
    //       this.appIsNotLoading();
    //     }, 750);
    //   });
    // } else {
    //   this.getCategories();
    // }
    this.getCategories();
  },
};
</script>
