<template>
  <v-overlay class="loading-overlay" :color="color" :opacity="opacity" :z-index="zIndex" :value="active">
    <div class="loading"></div>
  </v-overlay>
</template>


<script>
export default {
  name: "LoadingOverlay",
  props: {
    zIndex: Number,
    active: Boolean,
    opacity: Number,
    color: String
  },
};
</script>