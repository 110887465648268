<template>
  <v-chip
    data-cy-element="bubble-tag"
    :data-cy-bubble-tag-name="name"
    v-model="bubbleTagItems"
    color="secondary"
    class="ma-2"
    :close="close"
    @click:close="onClickClose(item)"
  >
    <v-icon left> mdi-label </v-icon>
    {{ name }}
  </v-chip>
</template>


<script>
export default {
  name: "BubbleTagList",
  props: {
    item: Object,
    items: Array,
    name: String,
    close: Boolean,
  },
  computed: {
    bubbleTagItems: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("onClickClose", value || []);
      },
    },
  },
  methods: {
    removeItemFromItems() {
      return this.bubbleTagItems.filter((item) => item !== this.item);
    },
    onClickClose(item) {
      this.$emit("onClickClose", this.removeItemFromItems(item));
    },
  },
};
</script>