import Vuex from "vuex";
import Vue from "vue";
import authStore from "./auth/index";
import categoryStore from "./category/index";
import productStore from "./product/index";
import globalStore from "./global/index";
import userStore from "./user/index";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    authStore,
    categoryStore,
    productStore,
    globalStore,
    userStore
  }
});

export default store;
