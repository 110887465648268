export default {
  headers: {
    users: [
      {
        text: "Id",
        align: "start",
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        sortable: true,
        value: "name",
      },
      {
        text: "E-Mail",
        sortable: true,
        value: "email",
      },
      {
        text: "Admin",
        sortable: true,
        value: "is_admin",
      },
      /* {
        text: "Erstellungsdatum",
        sortable: true,
        value: "created_at",
      },
      {
        text: "Änderungsdatum",
        sortable: true,
        value: "updated_at",
      }, */
      {
        text: "Aktionen",
        sortable: false,
        value: "actions",
      },
    ],
    products: [
      {
        text: "Bild",
        sortable: false,
        value: "picture",
      },
      {
        text: "Modell",
        sortable: true,
        value: "attributes.title",
      },
      {
        text: "Aktionen",
        sortable: false,
        value: "actions",
      },
    ]
  },
};
