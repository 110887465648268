import Vue from "vue";

const mutations = {
    UPDATE_PRODUCTS: (state, products) => {
      Vue.set(state, "products", products);
    },
    UPDATE_FEATURES: (state, features) => {
      Vue.set(state, "features", features);
    },
    SEARCH_PRODUCT: (state, searchTerm) => {
      Vue.set(state, "searchTerm", searchTerm);
    },
    UPDATE_SELECTED_FEATURES: (state, features) => {
      Vue.set(state, "selectedFeatures", features);
    },
    UPDATE_PRODUCT_DETAILS: (state, product) => {
      Vue.set(state.productDetails, "product", product);
    },
    UPDATE_RELATED: (state, related) => {
      Vue.set(state.productDetails, "related", related);
    },
    UPDATE_CURRENT_PAGE: (state, page) => {
      Vue.set(state, "currentPage", page);
    },
    UPDATE_PAGE_COUNT: (state, pageCount) => {
      Vue.set(state, "pageCount", pageCount);
    },
    UPDATE_PRODUCT_COUNT: (state, productCount) => {
      Vue.set(state, "productCount", productCount);
    },
};

export default mutations;
