const HelperService = {
  removeItemsFromArrayIfIndexBiggerThan(array, conditionValue) {
    for (let i = 0; i < array.length; i++) {
        i > conditionValue
          ? (array.splice(0, 1))
          : false;
      }
      return array;
  },
  removeItemsFromArrayIfIndexSmallerThan(array, conditionValue) {
    for (let i = 0; i < array.length; i++) {
        i < conditionValue
          ? (array.splice(0, 1))
          : false;
      }
      return array;
  },
  removeItemsFromArrayIfIndexEqual(array, conditionValue) {
    for (let i = 0; i < array.length; i++) {
        i === conditionValue
          ? (array.splice(0, 1))
          : false;
      }
      return array;
  },
  removeUndefinedItemsFromArray(array) {
    for (let i = 0; i < array.length; i++) {
      array[i] === undefined
        ? (array.splice(i, 1), i--)
        : false;
    }
    return array;
  }
};

export default HelperService;
