<template>
  <v-card data-cy-element="product-card-list" class="grey lighten-3 pa-8" v-if="products !== []">
    <v-row v-for="product in products" :key="product.id">
      <v-col cols="12" v-if="product.attributes.title">
        <ProductCard :productData="product" />
      </v-col>
    </v-row>
  </v-card>
</template>


<script>
import ProductCard from "../atome/ProductCard.vue";

export default {
  name: "ProductCardList",
  props: {
    products: Array,
  },
  components: { ProductCard }
};
</script>