<template>
  <v-form ref="form" v-model="valid">
    <v-text-field
      :id="`${elementId}-user-name`"
      v-model="userName"
      :counter="35"
      label="Name"
      :rules="[rules.required]"
      required
    ></v-text-field>

    <v-text-field
      :id="`${elementId}-user-email`"
      v-model="userEmail"
      label="E-Mail"
      :counter="50"
      :rules="[rules.required, rules.email]"
      :append-icon="'mdi-at'"
      required
    ></v-text-field>

    <template v-if="createForm">
      <v-text-field
        :id="`${elementId}-user-password`"
        v-model="password"
        :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPwd ? 'text' : 'password'"
        :counter="20"
        :rules="[rules.required]"
        label="Passwort"
        @click:append="showPwd = !showPwd"
        required
      ></v-text-field>

      <v-text-field
        :id="`${elementId}-user-password-confirmation`"
        v-model="passwordConfirmation"
        :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPwd ? 'text' : 'password'"
        :counter="20"
        :rules="[rules.required]"
        label="Passwort-wiederholen"
        @click:append="showPwd = !showPwd"
      ></v-text-field>
    </template>

    <v-switch
      :id="`${elementId}-user-admin`"
      v-model="userIsAdmin"
      :label="'Admin'"
    ></v-switch>

    <v-btn
      :id="`${elementId}-user-submit`"
      :disabled="!valid"
      :loading="loading"
      type="submit"
      class="mt-4"
      large
      color="success"
      @click="onSubmitForm()"
    >
      Speichern
    </v-btn>
  </v-form>
</template>


<script>
import inputRules from "@/helpers/input-rules";

export default {
  name: "UserForm",
  props: {
    elementId: String,
    id: Number,
    name: String,
    email: String,
    isAdmin: Boolean,
    createForm: Boolean,
  },
  data: () => ({
    loading: false,
    valid: false,
    formName: "",
    formEmail: "",
    password: "",
    passwordConfirmation: "",
    formIsAdmin: false,
    showPwd: false,
    rules: {
      required: inputRules.required,
      email: inputRules.email,
    },
  }),
  computed: {
    userName: {
      get() {
        return this.name || this.formName;
      },
      set(name) {
        return (this.formName = name);
      },
    },
    userEmail: {
      get() {
        return this.email || this.formEmail;
      },
      set(email) {
        return (this.formEmail = email);
      },
    },
    userIsAdmin: {
      get() {
        return this.isAdmin || this.formIsAdmin;
      },
      set(isAdmin) {
        return (this.formIsAdmin = isAdmin);
      },
    },
  },
  methods: {
    onSubmitForm() {
      this.loading = true;
      this.$emit("onSubmitForm", {
        id: this.id ? this.id : undefined,
        name: this.userName,
        email: this.userEmail,
        password: this.password || undefined,
        passwordConfirmation: this.passwordConfirmation || undefined,
        isAdmin: this.formIsAdmin,
      });
      this.resetForm();
      this.loading = false;
    },
    resetForm() {
      this.$refs.form.reset();
    },
  }
};
</script>