<template>
  <v-container data-cy-page="admin-page">
    <v-row class="my-sm-8">
      <v-col cols="12">
        <div class="text-h5">Admin-Panel</div>
        <v-divider class="my-6"></v-divider>
      </v-col>
      <v-col cols="12">
        <TopicHeader
          :divider="true"
          :text="'Benutzer'"
          :color="'white'"
          :bgColor="'primary'"
          :icon="'mdi-account-group'"
        />
      </v-col>
      <v-col cols="12" sm="6"
        ><v-text-field
          v-model="searchTerm"
          append-icon="mdi-magnify"
          label="Suchbegriff"
          single-line
          hide-details
          clearable
        ></v-text-field
      ></v-col>
      <v-col cols="12">
        <DataTable
          v-if="users.length > 0"
          class="pb-8"
          :headers="userDataTableHeaders"
          :items="users"
          :searchTerm="searchTerm"
          :editAction="true"
          :deleteAction="true"
          @onClickEdit="setCurrentEditUser"
          @onClickDelete="setCurrentDeleteUser"
        />
        <UserModalBox
          :active="currentEditUser.name ? true : false"
          :title="currentEditUser.name"
          :user="currentEditUser"
          @onSubmitForm="updateUser"
          @onClickAbort="setCurrentEditUser"
        />
        <ConfirmModalBox
          :active="currentDeleteUser.name ? true : false"
          :color="'error'"
          :title="'Benutzer löschen'"
          :message="`Soll der Benutzer '${currentDeleteUser.name}' wirklich gelöscht werden?`"
          @onClickAbort="setCurrentDeleteUser"
          @onClickConfirm="deleteUser(currentDeleteUser)"
        />
      </v-col>
      <v-col cols="12">
        <TopicHeader
          :divider="true"
          :text="'Neuen Benutzer anlegen'"
          :color="'white'"
          :bgColor="'primary'"
          :icon="'mdi-account-plus'"
        />
      </v-col>
      <v-col cols="12" sm="8">
        <UserForm :elementId="'add'" class="pb-12" :createForm="true" @onSubmitForm="createUser" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TopicHeader from "@/components/atome/TopicHeader.vue";
import DataTable from "@/components/atome/DataTable.vue";

import UserForm from "@/components/molecules/UserForm.vue";
import UserModalBox from "@/components/molecules/UserModalBox.vue";
import ConfirmModalBox from "@/components/molecules/ConfirmModalBox.vue";

import { mapActions, mapGetters } from "vuex";
import DataTableConfig from "@/config/data-table";

export default {
  name: "AdminView",
  components: {
    TopicHeader,
    UserForm,
    DataTable,
    UserModalBox,
    ConfirmModalBox,
  },
  data: () => ({
    userDataTableHeaders: DataTableConfig.headers.users,
    currentEditUser: {},
    currentDeleteUser: {},
    searchTerm: "",
  }),
  computed: {
    ...mapGetters({
      users: "userStore/users",
    }),
  },
  methods: {
    ...mapActions({
      getAllUsers: "userStore/getAllUsers",
      createUser: "userStore/createUser",
      updateUser: "userStore/updateUser",
      deleteUser: "userStore/deleteUser",
    }),
    getTableHeaders(data) {
      return Object.keys(data);
    },
    setCurrentEditUser(user) {
      this.currentEditUser = user;
    },
    setCurrentDeleteUser(user) {
      this.currentDeleteUser = user;
    },
  },
  mounted() {
    this.getAllUsers();
  },
};
</script>
