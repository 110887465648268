export default [
  {
    id: 1,
    title: "Spezifikationen",
  },
  // {
  //   id: 2,
  //   title: "Preise",
  // },
  {
    id: 3,
    title: "Ähnliche Produkte",
  },
];
