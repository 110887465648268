import vuexHistoryStore from "@/plugins/vuexHistoryStore/vuexHistoryStore.js";


const actions = {
  isLoading: ({ commit }) => {
    commit("UPDATE_LOADING_STATE", true);
  },
  isNotLoading: ({ commit }) => {
    setTimeout(() => {
      commit("UPDATE_LOADING_STATE", false);
    }, 700);
  },
  setMessage: ({ commit }, message) => {
    commit("UPDATE_ERROR_MESSAGE", message);
  },
  restoreHistoryState: ({ rootState }) => {
    return vuexHistoryStore.restoreHistoryState(rootState);
  }
};

export default actions;
