import ApiService from "@/api/api.service";

export const ACCESS_TOKEN = "access_token";
export const REFRESH_TOKEN = "refresh_token";
export const STATE = "vuex";

export default {
  getAccessToken() {
    return window.localStorage.getItem(ACCESS_TOKEN);
  },
  getRefreshToken() {
    return window.localStorage.getItem(REFRESH_TOKEN);
  },
  saveToken(accessToken, refreshToken) {
    window.localStorage.setItem(ACCESS_TOKEN, accessToken);
    window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
  },
  destroyToken() {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(REFRESH_TOKEN);
  },
  destroyState() {
    window.localStorage.removeItem(STATE);
  },
  refreshToken() {
    const refreshCall = ApiService.post("oauth/token", {
      grant_type: "refresh_token",
      refresh_token: this.getRefreshToken(),
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      scope: null,
    }).then((response) => {
      this.saveToken(response.data.access_token, response.data.refresh_token);
      return Promise.resolve(true);
    });

    return refreshCall;
  },
};
