const getters = {
    products: (state) => state.products,
    features: (state) => state.features,
    currentPage: (state) => state.currentPage,
    pageCount: (state) => state.pageCount,
    productCount: (state) => state.productCount,
    searchTerm: (state) => state.searchTerm,
    product: (state) => state.productDetails.product,
    related: (state) => state.productDetails.related,
    selectedFeatures: (state) => state.selectedFeatures
};

export default getters;