import ApiService from "@/api/api.service";

const CategoryService = {
  index(params) {
    return ApiService.query("api/v1/categories", {
      params: params,
    });
  },
  show(slug) {
    slug += "?include=categorySuppliers,categoryFeatures";
    return ApiService.get("api/v1/categories", slug);
  },
};

export default CategoryService;
