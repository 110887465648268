import Vue from "vue";

const mutations = {
    UPDATE_CATEGORIES: (state, categories) => {
      Vue.set(state, "categories", categories);
    },
    UPDATE_SUB_CATEGORIES: (state, subCategories) => {
      Vue.set(state, "subCategories", subCategories);
    },
    UPDATE_SUPPLIERS: (state, suppliers) => {
      Vue.set(state, "suppliers", suppliers);
    },
    UPDATE_MAIN_CATEGORY: (state, mainCategoryId) => {
      Vue.set(state, "mainCategory", mainCategoryId);
    },
    UPDATE_SUB_CATEGORY: (state, subCategoryId) => {
      Vue.set(state, "subCategory", subCategoryId);
    },
    UPDATE_SUPPLIER: (state, supplierId) => {
      Vue.set(state, "supplier", supplierId);
    },
};

export default mutations;