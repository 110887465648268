import ApiService from "@/api/api.service";

const ProductService = {
  index(params) {
    return ApiService.query("api/v1/products", {
      params: params,
    });
  },
  show(slug) {
    slug += "?include=supplier,features";
    return ApiService.get("api/v1/products", slug);
  },
  related(params) {
    return ApiService.query("api/v1/products/" + params);
  },
  byEAN(params) {
    return ApiService.get("/api/v1/product-by-ean/" + params);
  }
};

export default ProductService;
