<template>
  <div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>
