import Vue from "vue";
import Router from "vue-router";
import authenticate from "../middleware/AuthMiddleware";
import store from "@/store/index";

// Views
import MainView from "@/views/MainView";
import LoginView from "@/views/LoginView";
import DashboardView from "@/views/DashboardView";
import ProductView from "@/views/ProductView";
import NotFoundView from "@/views/NotFoundView";
import AdminView from "@/views/AdminView";

import PlaygroundView from "@/views/PlaygroundView";

import VuexHistoryStore from "@/plugins/vuexHistoryStore/vuexHistoryStore";

Vue.use(Router);

const routes = [
  {
    name: "main",
    path: "/",
    component: MainView,
    beforeEnter: authenticate,
    redirect: "/",
    children: [
      {
        name: "home",
        path: "",
        component: DashboardView,
      },
      {
        name: "playground",
        path: "playground",
        component: PlaygroundView,
      },
      {
        name: "productDetail",
        path: "product/:id",
        component: ProductView,
        props: true,
      },
      {
        name: "adminPanel",
        path: "admin",
        component: AdminView,
      },
    ],
  },
  {
    name: "login",
    path: "/login",
    component: LoginView,
  },
  {
    name: "notFound",
    path: "*",
    redirect: "/login",
    component: NotFoundView,
  },
];

const router = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === "playground" && from.name === "home") {
    VuexHistoryStore.resetHistoryStore();
    store.dispatch("globalStore/isLoading");
    store.dispatch("categoryStore/setMainCategory", null);
    store.dispatch("categoryStore/setSuppliers", []);
    store.dispatch("categoryStore/setSupplier", null);
    store.dispatch("productStore/setSelectedFeatures", []);
    store.dispatch("productStore/setFeatures", []);
    store.dispatch("productStore/setProducts", []);
  }

  if (
    from.name === "playground" &&
    (to.name === "home" || to.name === "adminPanel")
  ) {
    VuexHistoryStore.resetHistoryStore();
    store.dispatch("globalStore/isLoading");
    store.dispatch("categoryStore/setMainCategory", null);
    store.dispatch("categoryStore/setSuppliers", []);
    store.dispatch("categoryStore/setSupplier", null);
    store.dispatch("productStore/setSelectedFeatures", []);
    store.dispatch("productStore/setFeatures", []);
    store.dispatch("productStore/setProducts", []);
  }

  to.name === "productDetail" ||
  from.name === "productDetail" ||
  to.name === "adminPanel" ||
  from.name === "adminPanel"
    ? store.dispatch("globalStore/isLoading")
    : false;
  if (to.name !== "login") {
    setTimeout(() => {
      next();
    }, 750);
  } else if (to.name === "login") {
    next();
  }
});

router.afterEach(() => {
  const preLoading = document.getElementById("loading_wrap");
  if (preLoading) {
    setTimeout(() => {
      preLoading.style.display = "none";
    }, 500);
  }

  /* Don´t forget to set the resetStoreCount to 20 */

  /* if (VuexHistoryStore.containStates && to.name === "home") {
    const result = VuexHistoryStore.searchCurrentHistoryState();
    if (
      result.length > 0 &&
      !VuexHistoryStore.currenHistoryStateIsEqualWithLast()
    ) {
      store.dispatch("globalStore/restoreHistoryState").then(() => {
        store.dispatch("globalStore/isNotLoading");
      });
    } else if (
      (result.length > 0 &&
        VuexHistoryStore.currenHistoryStateIsEqualWithLast()) ||
      (result.length === 0 &&
        !VuexHistoryStore.currenHistoryStateIsEqualWithLast())
    ) {
      setTimeout(() => {
        store.dispatch("globalStore/isNotLoading");
      }, 500);
    }
  } */
});

export default router;
