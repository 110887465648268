export default {
  options: {
    chart: {
      id: "",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
      legend: {
        show: true,
        position: "top",
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
      categories: [],
    },
  },
  series: [],
};
