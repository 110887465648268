import Vue from "vue";

const mutations = {
    UPDATE_LOADING_STATE: (state, loadingState) => {
        Vue.set(state, "loading", loadingState);
    },
    UPDATE_ERROR_MESSAGE: (state, message) => {
      Vue.set(state, "message", message);
    }
};

export default mutations;