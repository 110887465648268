<template>
  <div class="tab-slider" data-cy-element="tab-slider">
    <v-tabs class="rounded rounded-b-0" v-model="tab" background-color="secondary" dark>
      <v-tab :data-cy-tab-slider-id="tab.id" class="text-h6" v-for="(tab, i) in tabs" :key="i">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.id" class="pa-md-12">
        <slot :name="tab.id" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>


<script>
export default {
  name: "TabSlider",
  data: () => ({
    tab: null,
  }),
  props: {
    tabs: Array,
  },
};
</script>