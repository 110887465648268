const StorageService = {
  dataStorage(storage) {
    return window[storage];
  },
  getDataFromStorage(storage, id) {
    return JSON.parse(this.dataStorage(storage).getItem(id))
  },
  removeDataFromStorages(storage, id) {
    this.dataStorage(storage).removeItem(id)
  },
  addDataToStorage(storage, id, value) {
    this.dataStorage(storage).setItem(id, JSON.stringify(value))
  },
  clearDataStorage(storage) {
    this.dataStorage(storage).clear();
  }
};

export default StorageService;
