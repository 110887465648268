/* eslint-disable no-console */
import axios from "axios";
import JwtService from "@/api/jwt.service";
import router from "@/router/router";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from "@/api/jwt.service";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 60000,
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
});

service.interceptors.request.use(
  (config) => {
    const token = JwtService.getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    let responseData = response.data
    if (!responseData.data) {
      responseData = response;
    }
    return responseData;
  },
  (error) => {
    console.info(error);
    if (error.config && error.response && error.response.status === 401) {
      if (
        (error.response.data.message === "The refresh token is invalid.") ||
        (error.response.data.message === "Unauthenticated.") 
      ) {
        window.localStorage.removeItem(ACCESS_TOKEN);
        window.localStorage.removeItem(REFRESH_TOKEN);
        window.localStorage.removeItem("vuex");
        router.push({ name: "login" });
      } else if (
        error.response.data.error !== "Invalid credentials" &&
        error.response.data.message !== "The refresh token is invalid."
      ) {
        return JwtService.refreshToken().then(() => {
          const refreshToken = JwtService.getAccessToken();
          error.config.headers.Authorization = `Bearer ${refreshToken}`;
          return axios.request(error.config);
        });
      }
    }
    return Promise.reject(error);
  }
);

const ApiService = {
  query(resource, params) {
    return service.get(resource, params);
  },
  get(resource, slug = null, config = {}) {
    let url = resource;
    if (slug) {
      url = `${resource}/${slug}`;
    }
    return service.get(url, config);
  },
  post(resource, params, config = {}) {
    return service.post(`${resource}`, params, config);
  },
  update(resource, slug, params, config = {}) {
    return service.put(`${resource}/${slug}`, params, config);
  },
  delete(resource, slug) {
    let url = resource;
    if (slug) {
      url = `${resource}/${slug}`;
    }
    return service.delete(url);
  },
};

export default ApiService;
