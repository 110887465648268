<template>
  <div class="navigation-bar">
    <v-app-bar fixed color="primary" dark>
      <v-app-bar-nav-icon
        class="navigation-bar__mobile-menu-toggle"
        @click="menu = true"
      ></v-app-bar-nav-icon>
      <router-link :to="'/'">
        <v-toolbar-title class="app-title d-flex align-center ml-1">
          <img :src="logoUrl" width="39" alt="logo" />
          <span class="app-title__text"
            >HYVE THINGS<span v-if="$route.name === 'playground'">
              (Playground)</span
            ></span
          >
        </v-toolbar-title>
      </router-link>
      <v-spacer></v-spacer>
      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            class="navigation-bar__desktop-menu-toggle mr-1"
            icon
            x-large
            v-on="on"
          >
            <v-avatar color="white" size="48">
              <v-icon color="primary">mdi-account</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="navigation-bar__desktop-menu">
          <v-list-item-content class="justify-center pa-4">
            <div class="mx-auto text-center">
              <template v-if="user"> 
                <v-avatar color="primary">
                  <v-icon color="white">mdi-account</v-icon>
                </v-avatar>
                <h3 class="mt-2">{{ user.name }}</h3>
                <p class="text-caption mt-1">{{ user.email }}</p>
                <v-divider class="my-3"></v-divider>
                <v-btn text :to="'/'"> Produkte </v-btn>
                <v-divider class="my-3"></v-divider>
                <template v-if="user.isAdmin">
                  <v-btn text :to="'/admin'"> Admin Panel </v-btn>
                  <v-divider class="my-3"></v-divider>
                </template>
                <template v-if="user.isAdmin">
                  <v-btn text :to="'/playground'"> Playground </v-btn>
                  <v-divider class="my-3"></v-divider>
                </template>
              </template>
              <v-btn @click="onSubmitLogout()" text>Abmelden</v-btn>
            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="menu" fixed temporary>
      <v-list nav dense>
        <v-list-item v-if="user">
          <v-avatar color="primary">
            <v-icon color="white">mdi-account</v-icon>
          </v-avatar>
          <h3 class="ml-3">{{ user.name }}</h3>
        </v-list-item>

        <v-divider v-if="user" class="my-3"></v-divider>
        <v-list-item-group active-class="primary--text">
          <v-list-item class="p-0" :to="'/'">
            <v-list-item-icon>
              <v-icon>mdi-sitemap</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Produkte</v-list-item-title>
          </v-list-item>

          <!-- <v-list-item v-if="user">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Meine Daten</v-list-item-title>
          </v-list-item> -->

          <v-list-item v-if="user.isAdmin" :to="'/admin'">
            <v-list-item-icon>
              <v-icon>mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin-Panel</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="user.isAdmin" :to="'/playground'">
            <v-list-item-icon>
              <v-icon>mdi-seesaw</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Playground</v-list-item-title>
          </v-list-item>

          <v-list-item @click="onSubmitLogout()">
            <v-list-item-icon>
              <v-icon>mdi-power-standby</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>


<script>
export default {
  name: "NavigationBar",
  props: {
    user: Object,
  },
  data: () => ({
    logoUrl: `${process.env.VUE_APP_BASE_URL}/hyve-logo-only-white.svg`,
    menu: false,
  }),
  methods: {
    onSubmitLogout() {
      this.$emit("onSubmitLogout");
    },
  },
};
</script>