import ApiService from "@/api/api.service";

const AuthService = {
  login(email, password) {
    return ApiService.post("oauth/token", {
      grant_type: "password",
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      username: email,
      password: password,
      scope: null,
    });
  }
};

export default AuthService;
