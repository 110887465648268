<template>
  <v-container>
    <v-row data-cy-page="product-page" class="my-sm-8" v-if="product">
      <v-col cols="12">
        <v-btn to="/" color="secondary" large
          ><v-icon color="white"> mdi-arrow-left </v-icon></v-btn
        >
      </v-col>
      <v-col cols="12">
        <div data-cy-element="product-title" class="text-h5">
          {{ product.attributes.title }}
        </div>
        <v-divider class="my-6"></v-divider>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-row>
            <v-col
              cols="12"
              md="5"
              class="d-flex justify-center align-center"
              v-if="
                product.attributes.pic && product.attributes.pic.width !== '0'
              "
            >
              <img
                :width="
                  (product.attributes.pic.width ||
                    product.attributes.high_pic.width) / 1.5
                "
                :src="
                  imageProxy(
                    product.attributes.pic.url ||
                      product.attributes.high_pic.url
                  )
                "
              />
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="d-flex justify-center align-center text-h5"
              v-if="
                (product.attributes.pic &&
                  product.attributes.pic.width === '0') ||
                (!product.attributes.pic && !product.attributes.high_pic) ||
                (product.attributes.high_pic &&
                  product.attributes.high_pic === '0')
              "
            >
              Kein Bild vorhanden
            </v-col>
            <v-col
              cols="12"
              md="5"
              class="d-flex justify-center align-center"
              v-if="!product.attributes.pic && product.attributes.high_pic"
            >
              <img
                :width="product.attributes.high_pic.width / 1.5"
                :src="imageProxy(product.attributes.high_pic.url)"
              />
            </v-col>
            <v-col cols="12" md="7" class="pa-8">
              <div class="text-h5 mb-4">Produktinformationen</div>
              <div data-cy-element="product-id" class="mb-6">
                <div class="text-h6 mb-2">HYVE-ID</div>
                {{ product.id }}
              </div>
              <div class="mb-6">
                <div class="text-h6 mb-2">Modell Name</div>
                {{ product.attributes.name }}
              </div>
              <div class="mb-6" v-if="product.attributes.long_description">
                <div class="text-h6 mb-2">Beschreibung</div>
                {{ product.attributes.long_description }}
              </div>
              <div class="mb-6" v-if="product.attributes.released_at">
                <div class="text-h6 mb-2">Erscheinungsdatum</div>
                {{ dateProxy(product.attributes.released_at) }}
              </div>
              <div
                data-cy-element="product-ean"
                class="mb-6"
                v-if="
                  product.attributes.ean_upc &&
                  product.attributes.ean_upc.length > 0
                "
              >
                <div class="text-h6 mb-2">EAN</div>
                {{ product.attributes.ean_upc[0] }}
              </div>
              <div
                class="mb-6"
                v-if="
                  product.relationships.supplier.data.attributes
                    .logo_medium_pic &&
                  product.relationships.supplier.data.attributes.logo_medium_pic
                    .width !== '0' &&
                  product.relationships.supplier.data.attributes.logo_medium_pic
                    .height !== '0'
                "
              >
                <div class="text-h6 mb-2">Hersteller</div>
                <img
                  :width="
                    product.relationships.supplier.data.attributes
                      .logo_medium_pic.width / 3
                  "
                  :height="
                    product.relationships.supplier.data.attributes
                      .logo_medium_pic.height / 3
                  "
                  :src="
                    imageProxy(
                      product.relationships.supplier.data.attributes
                        .logo_medium_pic.url
                    )
                  "
                />
              </div>
              <div
                class="mb-6"
                v-if="
                  product.relationships.price &&
                  product.relationships.price.data[0].value
                "
              >
                <div class="text-h6 mb-2">Marktpreis</div>
                <div class="text-h4 font-weight-bold secondary--text">
                  {{ `${product.relationships.price.data[0].value} €` }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="py-8">
        <v-card>
          <TabSlider :tabs="tabs">
            <template v-slot:1 v-if="features">
              <ExpansionPanelAccordion
                :accordion="true"
                :multiple="true"
                :focusable="true"
              >
                <ExpansionPanel
                  v-for="(item, i) in features"
                  :key="`${i}-${item.id}`"
                  :focusable="true"
                  :title="item.groupName"
                >
                  <v-simple-table class="mt-4">
                    <tbody>
                      <tr v-for="(feature, i) in item.data" :key="i">
                        <td class="v-col col-6 text-left">
                          {{ feature.name }}
                        </td>
                        <td class="v-col col-6 text-left">
                          {{ feature.value }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </ExpansionPanel>
              </ExpansionPanelAccordion>
            </template>
            <!-- <template
              v-slot:2
              v-if="productPriceChart.options && productPriceChart.series"
            >
              <ApexChart
                width="100%"
                height="500px"
                type="line"
                :options="productPriceChart.options"
                :series="productPriceChart.series"
              />
            </template> -->
            <template v-slot:3 v-if="related || related > 0">
              <ProductCardList
                :products="Array.isArray(related) ? related : [...related]"
              />
            </template>
          </TabSlider>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import imageProxy from "@/helpers/image-proxy";
import dateProxy from "@/helpers/date-proxy";
import TabSlider from "@/components/molecules/TabSlider.vue";
import ProductCardList from "@/components/molecules/ProductCardList.vue";
import ExpansionPanelAccordion from "@/components/atome/ExpansionPanelAccordion.vue";
import ExpansionPanel from "@/components/atome/ExpansionPanel.vue";
import PriceChart from "@/config/price-chart";
import ProductTabs from "@/config/product-tabs";

export default {
  name: "ProductView",
  data: () => ({
    tabs: ProductTabs,
    features: [],
    priceChart: PriceChart,
  }),
  components: {
    TabSlider,
    ExpansionPanelAccordion,
    ExpansionPanel,
    ProductCardList,
  },
  computed: {
    ...mapGetters({
      product: "productStore/product",
      related: "productStore/related",
    }),
    productPriceChart: {
      get() {
        return this.priceChart;
      },
      set(value) {
        this.priceChart = value;
      },
    },
  },
  methods: {
    ...mapActions({
      getProduct: "productStore/loadProduct",
      getRelated: "productStore/loadRelated",
    }),
    sortFeaturesIntoFeatureGroups(featureData) {
      if (featureData) {
        featureData.map((feature) => {
          const featureGroup = this.features.some(
            (group) => group.id === feature.feature_group_id
          );
          !featureGroup
            ? (this.features[feature.feature_group_id] = {
                id: feature.feature_group_id,
                groupName: feature.feature_group_name,
                data: new Array(feature),
              })
            : this.features[feature.feature_group_id].data.push(feature);
        });
        for (let i = 0; i < this.features.length; i++) {
          this.features[i] === undefined
            ? (this.features.splice(i, 1), i--)
            : false;
        }
      }
    },
    initProductPriceChart(priceData) {
      this.productPriceChart.options.chart.id = "price-chart";
      this.productPriceChart.series = [];
      priceData.map((priceComparison) => {
        if (priceComparison.history && priceComparison.source) {
          this.productPriceChart.options.xaxis.categories = Object.keys(
            priceComparison.history
          );
          this.productPriceChart.series.push({
            name: priceComparison.source,
            data: Object.values(priceComparison.history),
          });
        }
      });
    },
  },
  mixins: [imageProxy, dateProxy],
  mounted() {
    this.getProduct(this.$route.params.id)
      .then((res) => {
        const featureData = res.data.relationships.features.data;
        //const priceData = res.data.relationships.price.data;
        this.sortFeaturesIntoFeatureGroups(featureData);
        //this.initProductPriceChart(priceData);
        this.getRelated(this.$route.params.id).catch(() => {});
      })
      .catch((err) => {
        console.log(err);
        this.$router.push("/").catch(() => {});
      });
  },
  unmounted() {
    this.features = [];
    this.product = {};
    this.related = {};
  },
};
</script>
