var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.product)?_c('v-row',{staticClass:"my-sm-8",attrs:{"data-cy-page":"product-page"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"to":"/","color":"secondary","large":""}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-arrow-left ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-h5",attrs:{"data-cy-element":"product-title"}},[_vm._v(" "+_vm._s(_vm.product.attributes.title)+" ")]),_c('v-divider',{staticClass:"my-6"})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',[(
              _vm.product.attributes.pic && _vm.product.attributes.pic.width !== '0'
            )?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","md":"5"}},[_c('img',{attrs:{"width":(_vm.product.attributes.pic.width ||
                  _vm.product.attributes.high_pic.width) / 1.5,"src":_vm.imageProxy(
                  _vm.product.attributes.pic.url ||
                    _vm.product.attributes.high_pic.url
                )}})]):_vm._e(),(
              (_vm.product.attributes.pic &&
                _vm.product.attributes.pic.width === '0') ||
              (!_vm.product.attributes.pic && !_vm.product.attributes.high_pic) ||
              (_vm.product.attributes.high_pic &&
                _vm.product.attributes.high_pic === '0')
            )?_c('v-col',{staticClass:"d-flex justify-center align-center text-h5",attrs:{"cols":"12","md":"5"}},[_vm._v(" Kein Bild vorhanden ")]):_vm._e(),(!_vm.product.attributes.pic && _vm.product.attributes.high_pic)?_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","md":"5"}},[_c('img',{attrs:{"width":_vm.product.attributes.high_pic.width / 1.5,"src":_vm.imageProxy(_vm.product.attributes.high_pic.url)}})]):_vm._e(),_c('v-col',{staticClass:"pa-8",attrs:{"cols":"12","md":"7"}},[_c('div',{staticClass:"text-h5 mb-4"},[_vm._v("Produktinformationen")]),_c('div',{staticClass:"mb-6",attrs:{"data-cy-element":"product-id"}},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("HYVE-ID")]),_vm._v(" "+_vm._s(_vm.product.id)+" ")]),_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("Modell Name")]),_vm._v(" "+_vm._s(_vm.product.attributes.name)+" ")]),(_vm.product.attributes.long_description)?_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("Beschreibung")]),_vm._v(" "+_vm._s(_vm.product.attributes.long_description)+" ")]):_vm._e(),(_vm.product.attributes.released_at)?_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("Erscheinungsdatum")]),_vm._v(" "+_vm._s(_vm.dateProxy(_vm.product.attributes.released_at))+" ")]):_vm._e(),(
                _vm.product.attributes.ean_upc &&
                _vm.product.attributes.ean_upc.length > 0
              )?_c('div',{staticClass:"mb-6",attrs:{"data-cy-element":"product-ean"}},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("EAN")]),_vm._v(" "+_vm._s(_vm.product.attributes.ean_upc[0])+" ")]):_vm._e(),(
                _vm.product.relationships.supplier.data.attributes
                  .logo_medium_pic &&
                _vm.product.relationships.supplier.data.attributes.logo_medium_pic
                  .width !== '0' &&
                _vm.product.relationships.supplier.data.attributes.logo_medium_pic
                  .height !== '0'
              )?_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("Hersteller")]),_c('img',{attrs:{"width":_vm.product.relationships.supplier.data.attributes
                    .logo_medium_pic.width / 3,"height":_vm.product.relationships.supplier.data.attributes
                    .logo_medium_pic.height / 3,"src":_vm.imageProxy(
                    _vm.product.relationships.supplier.data.attributes
                      .logo_medium_pic.url
                  )}})]):_vm._e(),(
                _vm.product.relationships.price &&
                _vm.product.relationships.price.data[0].value
              )?_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"text-h6 mb-2"},[_vm._v("Marktpreis")]),_c('div',{staticClass:"text-h4 font-weight-bold secondary--text"},[_vm._v(" "+_vm._s(((_vm.product.relationships.price.data[0].value) + " €"))+" ")])]):_vm._e()])],1)],1)],1),_c('v-col',{staticClass:"py-8",attrs:{"cols":"12"}},[_c('v-card',[_c('TabSlider',{attrs:{"tabs":_vm.tabs},scopedSlots:_vm._u([(_vm.features)?{key:"1",fn:function(){return [_c('ExpansionPanelAccordion',{attrs:{"accordion":true,"multiple":true,"focusable":true}},_vm._l((_vm.features),function(item,i){return _c('ExpansionPanel',{key:(i + "-" + (item.id)),attrs:{"focusable":true,"title":item.groupName}},[_c('v-simple-table',{staticClass:"mt-4"},[_c('tbody',_vm._l((item.data),function(feature,i){return _c('tr',{key:i},[_c('td',{staticClass:"v-col col-6 text-left"},[_vm._v(" "+_vm._s(feature.name)+" ")]),_c('td',{staticClass:"v-col col-6 text-left"},[_vm._v(" "+_vm._s(feature.value)+" ")])])}),0)])],1)}),1)]},proxy:true}:null,(_vm.related || _vm.related > 0)?{key:"3",fn:function(){return [_c('ProductCardList',{attrs:{"products":Array.isArray(_vm.related) ? _vm.related : [].concat( _vm.related )}})]},proxy:true}:null],null,true)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }