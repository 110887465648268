<template>
  <v-app>
    <v-main class="main">
      <router-view></router-view>
    </v-main>
    <LoadingOverlay
      color="white"
      :zIndex="500"
      :opacity="1"
      :active="loading"
    />
    <InfoModalBox
      :active="message.text !== ''"
      :title="message.title"
      :message="message.text"
      :color="message.colorType"
      @onSubmitClose="setMessage"
    />
  </v-app>
</template>

<script>
import LoadingOverlay from "@/components/atome/LoadingOverlay.vue";
import InfoModalBox from "@/components/atome/InfoModalBox.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    LoadingOverlay,
    InfoModalBox,
  },
  computed: {
    ...mapGetters({
      loading: "globalStore/loading",
      message: "globalStore/message",
      user: "authStore/user",
    }),
  },
  methods: {
    ...mapActions({
      appIsNotLoading: "globalStore/isNotLoading",
      setMessage: "globalStore/setMessage",
      getCurrentUser: "authStore/getCurrentUser",
    }),
  },
  mounted() {
    if (Object.keys(this.user).length === 0) {
      this.getCurrentUser().then(() => {
        this.appIsNotLoading();
      }).catch(() => this.$router.push("/login").catch(() => {}));
    } else {
      this.appIsNotLoading();
    }
  },
};
</script>