import ProductService from "@/api/product.service";
import productProxyHelper from "@/helpers/product-proxy";
//import VuexHistoryStore from "@/plugins/vuexHistoryStore/vuexHistoryStore.js";

import router from "@/router/router";

const actions = {
  fetchProducts: ({ dispatch, commit, state, rootState }) => {
    return new Promise((resolve, reject) => {
      let queryParameters = {
        "filter[category-id]":
          rootState.categoryStore.mainCategory === 9889
            ? rootState.categoryStore.subCategory
            : rootState.categoryStore.mainCategory,
        query: state.searchTerm,
        "filter[feature]": [],
        "filter[has_features]":
          rootState.categoryStore.mainCategory === 9889 ? undefined : 1,
        "filter[supplier-id]":
          rootState.categoryStore.supplier !== ""
            ? rootState.categoryStore.supplier
            : undefined,
        per_page: rootState.productStore.productCount,
        page: rootState.productStore.currentPage,
      };

      if (state.selectedFeatures.some((feature) => feature.id === 201102)) {
        queryParameters["filter[release_year]"] = state.selectedFeatures.filter(
          (feature) => {
            if (feature.id === 201102) {
              return feature.value;
            }
          }
        )[0].value;
      }

      if (state.selectedFeatures) {
        state.selectedFeatures.forEach(function (item) {
          if (item.id !== 201102) {
            queryParameters["filter[feature]"].push(item.id + "|" + item.value);
          }
        });
      }

      ProductService.index(queryParameters)
        .then((res) => {
          if (
            res &&
            res.facet_attributes &&
            res.facet_attributes.release_year
          ) {
            let features = res.facet_attributes.release_year
              ? [
                  {
                    id: 201102,
                    name: "Erscheinungsjahr",
                    values: res.facet_attributes.release_year.map((year) => ({
                      ...year,
                      presentation_value: year.value,
                    })),
                  },
                ]
              : [];
            if (
              res &&
              res.facet_attributes &&
              res.facet_attributes.features &&
              res.facet_attributes.features.length > 0
            ) {
              features = [...features, ...res.facet_attributes.features];
            }
            dispatch("setFeatures", features);
          } else {
            dispatch("setFeatures", []);
          }

          commit("UPDATE_PAGE_COUNT", res.meta.last_page);
          commit("UPDATE_PRODUCTS", res.data);
          resolve(res);
          //VuexHistoryStore.saveStoreSnapshot(rootState);
          dispatch("globalStore/isNotLoading", [], { root: true });
        })
        .catch((error) => {
          commit("UPDATE_PRODUCTS", []);
          reject(error);
          dispatch(
            "globalStore/setMessage",
            {
              title: "Fehler",
              text: "Es wurden keine Produkte gefunden!",
              colorType: "error",
            },
            { root: true }
          );
          dispatch("globalStore/isNotLoading", [], { root: true });
        });
    });
  },
  setProducts: ({ commit }, products) => {
    commit("UPDATE_PRODUCTS", products);
  },
  searchProductByEAN: ({ dispatch }, ean) => {
    dispatch("globalStore/isLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      ProductService.byEAN(`?ean=${ean}`)
        .then((res) => {
          if (res.data) {
            router.push(productProxyHelper.methods.productProxy(res.data.id));
          }
          resolve(res);
          //VuexHistoryStore.saveStoreSnapshot(rootState);
        })
        .catch((error) => {
          dispatch(
            "globalStore/setMessage",
            {
              title: "Fehler",
              text: "Es wurde kein Produkt gefunden!",
              colorType: "error",
            },
            { root: true }
          );
          dispatch("fetchProducts");
          reject(error);
        });
    });
  },
  searchProductByHyveId: ({ dispatch }, id) => {
    dispatch("globalStore/isLoading", true, { root: true });
    id = id.replace("HYVE", "");
    return new Promise((resolve, reject) => {
      ProductService.show(id)
        .then((res) => {
          if (res.data) {
            //VuexHistoryStore.saveStoreSnapshot(rootState);
            router.push(productProxyHelper.methods.productProxy(res.data.id));
          }
          resolve(res);
        })
        .catch((error) => {
          dispatch(
            "globalStore/setMessage",
            {
              title: "Fehler",
              text: "Es wurde kein Produkt gefunden!",
              colorType: "error",
            },
            { root: true }
          );
          dispatch("fetchProducts");
          reject(error);
        });
    });
  },
  setFeatures: ({ dispatch, commit }, features) => {
    dispatch("globalStore/isLoading", true, { root: true });
    commit("UPDATE_FEATURES", features);
  },
  setSelectedFeatures: ({ dispatch, commit }, selectedFeatures) => {
    dispatch("globalStore/isLoading", true, { root: true });
    commit("UPDATE_SELECTED_FEATURES", selectedFeatures);
  },
  selectFeature: ({ dispatch, commit }, selectedFeature) => {
    dispatch("globalStore/isLoading", true, { root: true });
    dispatch("setCurrentPage", 1);
    commit("UPDATE_SELECTED_FEATURES", selectedFeature);
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1000);
    dispatch("fetchProducts");
  },
  setSearchTerm: ({ commit }, searchTerm) => {
    commit("SEARCH_PRODUCT", searchTerm);
  },
  searchProduct: ({ dispatch, commit, rootState }, searchTerm) => {
    dispatch("globalStore/isLoading", true, { root: true });
    dispatch("setCurrentPage", 1);
    dispatch("setSelectedFeatures", []);
    commit("SEARCH_PRODUCT", searchTerm);
    if (searchTerm === "" &&
      rootState.categoryStore.mainCategory === 9889 &&
      rootState.categoryStore.subCategory === null
    ) {
      dispatch("setFeatures", []);
      dispatch("setProducts", []);
      dispatch("globalStore/isNotLoading", [], { root: true });
    } else {
      dispatch("fetchProducts");
    }
  },
  selectPage: ({ dispatch, commit }, page) => {
    dispatch("globalStore/isLoading", true, { root: true });
    commit("UPDATE_CURRENT_PAGE", page);
    setTimeout(() => {
      window.scroll(0, 0);
    }, 1000);
    dispatch("fetchProducts");
  },
  setCurrentPage: ({ commit }, page) => {
    commit("UPDATE_CURRENT_PAGE", page);
  },
  setPageCount: ({ commit }, pageCount) => {
    commit("UPDATE_PAGE_COUNT", pageCount);
  },
  selectProductCount: ({ dispatch, commit }, productCount) => {
    dispatch("globalStore/isLoading", true, { root: true });
    dispatch("setCurrentPage", 1);
    commit("UPDATE_PRODUCT_COUNT", productCount);
    dispatch("fetchProducts");
  },
  loadProduct: ({ dispatch, commit }, id) => {
    dispatch("globalStore/isLoading", [], { root: true });
    return new Promise((resolve, reject) => {
      ProductService.show(id)
        .then((res) => {
          commit("UPDATE_PRODUCT_DETAILS", res.data);
          resolve(res);
          dispatch("globalStore/isNotLoading", [], { root: true });
        })
        .catch((error) => {
          reject(error);
          dispatch("globalStore/isNotLoading", [], { root: true });
        });
    });
  },
  loadRelated: ({ commit }, related) => {
    return new Promise((resolve, reject) => {
      ProductService.related(related)
        .then((res) => {
          commit("UPDATE_RELATED", res.data);
          resolve(res);
        })
        .catch((error) => {
          commit("UPDATE_RELATED", null);
          reject(error);
        });
    });
  },
};

export default actions;
