import JwtService from "@/api/jwt.service";
import Vue from "vue";

const mutations = {
    SET_AUTH: (state, data) => {
      Vue.set(state, "isAuthenticated", true);
      JwtService.saveToken(data.accessToken, data.refreshToken);
    },
    SET_USER: (state, user) => {
      Vue.set(state, "user", user);
    },
    PURGE_AUTH: (state) => {
      Vue.set(state, "isAuthenticated", false);
      Vue.set(state, "user", {});
      JwtService.destroyState();
      JwtService.destroyToken();
    },
};

export default mutations;