export default (to, from, next) => {
  if (
    localStorage.getItem("access_token") != null &&
    localStorage.getItem("access_token").length > 0
  ) {
    next();
  } else {
    localStorage.removeItem("access_token");
    next("/login");
  }
};
