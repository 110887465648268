import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "../src/scss/style.scss";

import VueApexCharts from "vue-apexcharts";
import axios from "axios";

Vue.prototype.$http = axios;
Vue.config.productionTip = false;
Vue.use(VueApexCharts);

Vue.component("ApexChart", VueApexCharts);

export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
