import AuthService from "@/api/auth.service";
import UserService from "@/api/user.service";
import VuexHistoryStore from "@/plugins/vuexHistoryStore/vuexHistoryStore";

const actions = {
  login: ({ dispatch, commit }, { email, password }) => {
    return new Promise((resolve, reject) => {
      AuthService.login(email, password)
        .then((res) => {
          commit("SET_AUTH", {
            accessToken: res.data.access_token,
            refreshToken: res.data.refresh_token,
          });
          dispatch("globalStore/isLoading", [], {root:true});
          dispatch("getCurrentUser");
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout: ({ dispatch, commit }) => {
    dispatch("globalStore/isLoading", [], {root:true});
    return new Promise((resolve) => {
      VuexHistoryStore.resetHistoryStore();
      commit("PURGE_AUTH");
      resolve();
    });
  },
  getCurrentUser: ({ dispatch, commit }) => {
    dispatch("globalStore/isLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      UserService.getCurrentUser()
        .then((res) => {
          const user = {
            id: res.data.id,
            name: res.data.name,
            email: res.data.email,
            isAdmin: res.data.is_admin === 1 ? true : false
          };
          commit("SET_USER", user);
          resolve(user);
        })
        .catch(() => {
          reject();
          dispatch("globalStore/isNotLoading", true, { root: true });
        });
    });
  },
};

export default actions;
