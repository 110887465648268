<template>
  <v-container data-cy-element="search-bar" class="search-bar rounded px-md-0 py-0">
    <v-row class="d-flex justify-md-center align-md-center">
      <v-col cols="12" sm="6" md="2" v-if="categories">
        <v-select
        data-cy-input="main-category-selection"
          id="main-category-selection"
          v-model="currentCategory"
          :disabled="currentRequestType !== requestTypes.query"
          item-text="name"
          item-value="id"
          label="Kategorie"
          :items="categories"
          @change="onChangeCategorySelection()"
        >
        </v-select>
      </v-col>
      <template v-if="currentCategory === 9889 && subCategories.length > 0">
        <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-autocomplete
        data-cy-input="sub-category-selection"
          id="sub-category-selection"
          v-model="currentSubCategory"
          :disabled="currentRequestType !== requestTypes.query"
          item-text="name"
          item-value="id"
          label="Unterkategorien"
          :items="subCategories"
          @change="onChangeSubCategorySelection()"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="2" v-if="suppliers && currentSubCategory">
        <v-autocomplete
        data-cy-input="supplier-selection"
          id="supplier-selection"
          v-model="currentSupplier"
          :disabled="currentRequestType !== requestTypes.query"
          item-text="name"
          item-value="id"
          label="Hersteller"
          :items="suppliers"
          @change="onChangeSupplierSelection()"
          clearable
        >
        </v-autocomplete>
      </v-col>
      </template>
      <template v-if="currentCategory !== 9889">
      <v-col cols="12" sm="6" md="2" v-if="suppliers">
        <v-autocomplete
        data-cy-input="supplier-selection"
          id="supplier-selection"
          v-model="currentSupplier"
          :disabled="currentRequestType !== requestTypes.query"
          item-text="name"
          item-value="id"
          label="Hersteller"
          :items="suppliers"
          @change="onChangeSupplierSelection()"
          clearable
        >
        </v-autocomplete>
      </v-col>
      </template>
      <v-col cols="12" md="6">
        <v-form
          ref="form"
          @submit.prevent="currentSearchTerm ? onSubmitSearch() : null"
        >
          <v-row>
            <v-col cols="12" sm="8" lg="9">
              <v-text-field
              data-cy-input="search"
                id="search"
                v-model="currentSearchTerm"
                label="Suchbegriff"
                :placeholder="'z.b IPhone X, EAN, HYVE-ID ....'"
                clearable
                :type="'text'"
                @click:clear="onSubmitSearch(true)"
              ></v-text-field>
            </v-col>
            <v-col
              class="pb-8 pb-sm-3 d-flex justify-center align-center"
              cols="12"
              sm="auto"
              md="3"
            >
              <v-btn
              data-cy-cta="search-submit"
                id="search-button"
                :disabled="!searchTermSelection"
                color="primary"
                large
                @click="onSubmitSearch(false)"
                ><v-icon>mdi-magnify</v-icon> Suchen</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
export default {
  name: "SearchBar",
  props: {
    supplier: Number,
    suppliers: Array,
    category: Number,
    categories: Array,
    subCategory: Number,
    subCategories: Array,
    searchTerm: String
  },
  data: () => ({
    categorySelection: null,
    supplierSelection: null,
    subCategorySelection: null,
    searchTermSelection: "",
    requestTypes: {
      query: "onSubmitSearch",
      ean: "onSubmitEANSearch",
      id: "onSubmitIdSearch",
    },
    currentRequestType: "onSubmitSearch",
  }),
  watch: {
    searchTermSelection(newTerm) {
      const eanCheck13 = /^(\d{13})?$/;
      const eanCheck8 = /^(\d{8})?$/;
      const hyveIdCheck = /^(\bHYVE\d{8})?$/;
      if (
        newTerm !== "" &&
        (eanCheck13.test(newTerm) || eanCheck8.test(newTerm))
      ) {
        this.currentRequestType = this.requestTypes.ean;
      } else if (newTerm !== "" && hyveIdCheck.test(newTerm)) {
        this.currentRequestType = this.requestTypes.id;
      } else {
        this.currentRequestType = this.requestTypes.query;
      }
      if (newTerm === "") {
        this.onSubmitSearch(true);
      }
    },
  },
  computed: {
    currentCategory: {
      get() {
        return this.category;
      },
      set(value) {
        return (this.categorySelection = value);
      },
    },
    currentSupplier: {
      get() {
        return this.supplier;
      },
      set(value) {
        return (this.supplierSelection = value);
      },
    },
    currentSubCategory: {
      get() {
        return this.subCategory;
      },
      set(value) {
        return (this.subCategorySelection = value);
      },
    },
    currentSearchTerm: {
      get() {
        return this.searchTerm === null ? this.searchTermSelection : this.searchTerm;
      },
      set(value) {
        return (this.searchTermSelection = value);
      },
    },
  },
  methods: {
    onChangeCategorySelection() {
      this.supplierSelection = "";
      this.$emit("onChangeCategorySelection", this.categorySelection);
    },
    onChangeSupplierSelection() {
      this.$emit("onChangeSupplierSelection", this.supplierSelection);
    },
    onChangeSubCategorySelection() {
      this.$emit("onChangeSubCategorySelection", this.subCategorySelection);
    },
    onSubmitSearch(clear) {
      clear ? (this.searchTermSelection = "") : this.searchTermSelection;
      this.$emit(this.currentRequestType, this.searchTermSelection);
    },
  },
  mounted() {
    this.searchTermSelection = this.searchTerm;
    if (this.categories[0]) {
      this.categorySelection =
        this.currentCategory === null
          ? this.categories[0].id
          : this.currentCategory;
      this.$emit("onChangeCategorySelection", this.categorySelection);
    }
  },
};
</script>