var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-container',{staticClass:"mt-12 mb-6"},[_c('v-row',[_c('v-divider',{staticClass:"py-2"}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TopicHeader',{attrs:{"divider":true,"text":'Schnellfilter',"color":'white',"bgColor":'primary',"icon":'mdi-filter-variant'}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"id":"main-category-selection","item-text":"name","item-value":"id","label":"Kategorie","items":_vm.categories},model:{value:(_vm.currentCategory),callback:function ($$v) {_vm.currentCategory=$$v},expression:"currentCategory"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"id":"supplier-selection","item-text":"name","item-value":"id","label":"Hersteller","items":_vm.suppliers},model:{value:(_vm.currentSupplier),callback:function ($$v) {_vm.currentSupplier=$$v},expression:"currentSupplier"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticClass:"py-2"}),_c('v-row',[_vm._l((_vm.fixedFeatures),function(feature){return _c('v-col',{key:((Math.random()) + "-id-" + (feature.id) + "-value-" + (feature.presentation_value) + "-" + (Math.random())),attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(feature.name)+" "),_vm._l((feature.values),function(item){return _c('v-checkbox',{key:((Math.random()) + "-id-" + (feature.id) + "-value-" + (item.presentation_value) + "-" + (Math.random())),attrs:{"value":{
                  id: feature.id,
                  value: item.value,
                  name: item.presentation_value,
                },"id":((Math.random()) + "-id-" + (feature.id) + "-value-" + (item.presentation_value) + "-" + (Math.random()))},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(item.presentation_value)+" ")]},proxy:true}],null,true),model:{value:(_vm.activeCheckBoxes),callback:function ($$v) {_vm.activeCheckBoxes=$$v},expression:"activeCheckBoxes"}})})],2)}),(_vm.supplier)?_c('v-col',_vm._l((_vm.features),function(feature){return _c('v-row',{key:((Math.random()) + "-id-" + (feature.id) + "-value-" + (feature.presentation_value) + "-" + (Math.random()))},[((feature.id === 1766 || feature.id === 8730) && _vm.searchForFixedValues(feature.values))?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(feature.id === 1766 ? "Modellfarbe" : "")+" "+_vm._s(feature.id === 8730 ? "Speichergröße" : "")+" "),_vm._l((feature.values),function(item){return _c('div',{key:((Math.random()) + "-id-" + (feature.id) + "-value-" + (item.presentation_value) + "-" + (Math.random()))},[(_vm.showFixedValue(item.presentation_value))?_c('v-checkbox',{attrs:{"value":{
                        id: feature.id,
                        value: item.value,
                        name: item.presentation_value,
                      },"id":((Math.random()) + "-id-" + (feature.id) + "-value-" + (item.presentation_value) + "-" + (Math.random()))},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(item.presentation_value)+" ")]},proxy:true}],null,true),model:{value:(_vm.activeCheckBoxes),callback:function ($$v) {_vm.activeCheckBoxes=$$v},expression:"activeCheckBoxes"}}):_vm._e()],1)})],2):_vm._e()],1)}),1):_vm._e()],2)],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('TopicHeader',{attrs:{"divider":true,"text":'Produkte',"color":'white',"bgColor":'primary',"icon":'mdi-format-list-bulleted-square'}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suchbegriff","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.currentSearchTerm),callback:function ($$v) {_vm.currentSearchTerm=$$v},expression:"currentSearchTerm"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"headers":_vm.productDataTableHeaders,"editAction":false,"clickAction":true,"items":_vm.products,"searchTerm":_vm.currentSearchTerm,"itemsPerPage":-1,"hideFooter":true},on:{"onClickItem":_vm.setProduct}}),_c('v-divider',{staticClass:"mt-6"})],1),(_vm.currentProduct)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-end mb-4"},[_c('v-icon',{on:{"click":function($event){return _vm.setProduct(null, false)}}},[_vm._v("mdi-close")])],1),_c('v-text-field',{ref:"hyveId",attrs:{"append-icon":"mdi-clipboard-multiple-outline","label":"HYVE-ID","readonly":""},on:{"click":_vm.copyText,"click:append":_vm.copyText},model:{value:(_vm.product.id),callback:function ($$v) {_vm.$set(_vm.product, "id", $$v)},expression:"product.id"}})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }