const state = {
  categories: [],
  subCategories: [],
  suppliers: [],
  mainCategory: null,
  subCategory: null,
  supplier: null
};

export default state;
