<template>
  <v-expansion-panels :accordion="accordion" :focusable="focusable" :multiple="multiple">
    <slot />
  </v-expansion-panels>
</template>


<script>
export default {
  name: "ExpansionPanelAccordion",
  props: {
    focusable: Boolean,
    multiple: Boolean,
    accordion: Boolean
  },
};
</script>