<template>
  <div class="topic-header">
    <div
      :class="bgColor ? bgColor : ''"
      class="
        rounded
        text-h5
        white--text
        pa-4
        d-flex
        align-center
        justify-space-between
      "
    >
      <div class="d-flex align-center">
        <v-icon :color="color" class="mr-2">{{ icon }}</v-icon>
        {{ text }}
      </div>
      <slot></slot>
    </div>
    <v-divider v-if="divider" class="mt-6"></v-divider>
  </div>
</template>

<script>
export default {
  name: "TopicHeader",
  props: {
    text: String,
    color: String,
    icon: String,
    bgColor: String,
    divider: Boolean,
  },
};
</script>
