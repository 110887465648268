<template>
  <v-container data-cy-page="login-page" class="login__wrapper">
    <v-row class="justify-center">
      <v-col cols="12" sm="8" md="8" lg="4">
        <v-card>
          <v-card-title class="app-title">
            <img src="hyve-logo-only-white.svg" width="39" alt="logo" />
            <span class="app-title__text">HYVE THINGS</span>
          </v-card-title>
          <v-form
            data-cy-form="login-form"
            id="login-form"
            ref="form"
            v-model="valid"
            class="pa-6 text-center"
          >
            <v-text-field
              data-cy-input="login-email"
              id="email"
              v-model="email"
              :append-icon="'mdi-at'"
              :rules="[rules.required]"
              label="E-Mail"
              required
            ></v-text-field>
            <v-text-field
              data-cy-input="login-password"
              id="password"
              v-model="password"
              :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :type="showPwd ? 'text' : 'password'"
              name="input-10-2"
              label="Passwort"
              @click:append="showPwd = !showPwd"
            ></v-text-field>
            <v-btn
              data-cy-cta="login-submit"
              :disabled="!valid || loading"
              :loading="loading"
              large
              class="mb-4"
              color="primary"
              type="submit"
              @click="onSubmit()"
              ><v-icon>mdi-login</v-icon> Anmelden
            </v-btn>
          </v-form>
          <div class="pb-4 text-center red--text" v-if="error !== ''">
            {{ error }}
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import inputRules from "../helpers/input-rules";
import { mapActions } from "vuex";

export default {
  name: "LoginView",
  data: () => ({
    valid: false,
    email: "",
    password: "",
    showPwd: false,
    error: "",
    rules: {
      required: inputRules.required,
    },
    loading: false,
  }),
  methods: {
    ...mapActions({
      appIsNotLoading: "globalStore/isNotLoading",
    }),
    onSubmit() {
      const errorMessage = "Anmeldung fehlgeschlagen, bitte Eingabe überprüfen";
      if (this.$refs.form.validate() && this.email && this.password) {
        this.loading = true;
        this.$store
          .dispatch("authStore/login", {
            email: this.email,
            password: this.password,
          })
          .then(() => {
            this.error = "";
            this.$router.push("/").catch(() => {
              this.loading = false;
              this.error = errorMessage;
            });
          })
          .catch(() => {
            this.loading = false;
            this.error = errorMessage;
          });
      } else {
        this.loading = false;
        this.error = errorMessage;
      }
    },
  },
  mounted() {
    this.appIsNotLoading();
  },
};
</script>
