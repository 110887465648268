<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="searchTerm"
    :items-per-page="itemsPerPage"
    :hide-default-footer="hideFooter"
  >
    <template v-slot:[`item.picture`]="{ item }">
      <img
        v-if="item.attributes.pic && item.attributes.pic.width !== '0'"
        :width="item.attributes.pic.width / 12"
        :height="item.attributes.pic.height / 12"
        :src="imageProxy(item.attributes.pic.url)"
      />
    </template>
    <template v-slot:[`item.is_admin`]="{ item }">
      <v-icon v-if="item.is_admin === false"> mdi-checkbox-blank-circle-outline </v-icon>
      <v-icon v-if="item.is_admin === true"> mdi-checkbox-blank-circle </v-icon>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon v-if="clickAction" class="mr-2" @click="clickItem(item)">
        mdi-arrow-right
      </v-icon>
      <v-icon v-if="editAction" class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon v-if="deleteAction" class="mr-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import imageProxy from "../../helpers/image-proxy";

export default {
  name: "DataTable",
  props: {
    headers: Array,
    items: Array,
    editAction: Boolean,
    deleteAction: Boolean,
    clickAction: Boolean,
    searchTerm: String,
    itemsPerPage: Number,
    hideFooter: Boolean,
  },
  mixins: [imageProxy],
  methods: {
    editItem(item) {
      this.$emit("onClickEdit", item);
    },
    deleteItem(item) {
      console.info(item);
      this.$emit("onClickDelete", item);
    },
    clickItem(item) {
      this.$emit("onClickItem", item);
    },
  },
};
</script>
