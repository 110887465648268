export default [
  {
    id: 151,
    parent_id: 150,
    name: "Notebooks",
  },
  {
    id: 1893,
    parent_id: 8705,
    name: "Smartphones",
  },
  {
    id: 897,
    parent_id: 150,
    name: "Tablets",
  },
  {
    id: 1584,
    parent_id: 8760,
    name: "Fernseher",
  },
  {
    id: 9889,
    name: "Fahrrad / E-Bike",
  },
];
