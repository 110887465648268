<template>
  <v-dialog v-model="activeModal" width="500">
    <v-card>
      <v-card-title :class="color ? `${color} white--text` : `black--text`" class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text class="mt-5 black--text">
        {{ message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="onClickConfirm()"> Bestätigen </v-btn>
        <v-btn text @click="activeModal = false"> Abbrechen </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
export default {
  name: "ConfirmModalBox",
  props: {
    active: Boolean,
    title: String,
    message: String,
    color: String,
  },
  computed: {
    activeModal: {
      get() {
        return this.active;
      },
      set() {
        this.$emit("onClickAbort", {});
      },
    },
  },
  methods: {
    onClickConfirm() {
      this.$emit("onClickConfirm");
      this.$emit("onClickAbort", {});
    },
  }
};
</script>