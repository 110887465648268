const state = {
  products: [],
  features: [],
  currentPage: null,
  pageCount: 0,
  productCount: 30,
  searchTerm: null,
  selectedFeatures: [],
  productDetails: {
    product: null,
    related: [],
  },
};

export default state;
