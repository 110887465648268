<template>
  <v-card
    data-cy-element="product-card"
    :data-cy-product-id="productData.id"
    @click="
      $route.path === productProxy(productData.id)
        ? false
        : routeToProduct(productProxy(productData.id))
    "
    class="product-card pa-8"
  >
    <v-row>
      <v-col
        cols="12"
        lg="5"
        v-if="
          productData.attributes.pic &&
          productData.attributes.pic.width !== '0' &&
          productData.attributes.pic.height !== 0
        "
        class="d-flex align-center justify-center"
      >
        <img
          :width="productData.attributes.pic.width / 2.5"
          :src="imageProxy(productData.attributes.pic.url)"
        />
      </v-col>
      <v-col
        cols="12"
        lg="5"
        class="d-flex align-center justify-center"
        v-if="!productData.attributes.pic && productData.attributes.high_pic"
      >
        <img
          :width="productData.attributes.high_pic.width / 2.5"
          :src="imageProxy(productData.attributes.high_pic.url)"
        />
      </v-col>
      <v-col
        cols="12"
        lg="5"
        class="d-flex align-center justify-center grey--text"
        v-if="
          (productData.attributes.pic &&
            productData.attributes.pic.width === '0') ||
          (productData.attributes.high_pic &&
            productData.attributes.high_pic.width === '0')
        "
      >
        Kein Bild vorhanden
      </v-col>
      <v-col cols="12" lg="7">
        <div class="text-h6 mb-4">{{ productData.attributes.title }}</div>
        <div class="mb-4">HYVE-ID: {{ productData.id }}</div>
        <div
          class="mb-4"
          v-if="productData.attributes.released_at"
        >
          Erscheinungsdatum: {{ dateProxy(productData.attributes.released_at) }}
        </div>
        <template v-if="productData.attributes.long_description">
          <div class="text-subtitle-1 mb-4">Spezifikationen</div>
          <p>{{ productData.attributes.long_description }}</p>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import dateProxy from "@/helpers/date-proxy";
import imageProxy from "../../helpers/image-proxy";
import productProxy from "../../helpers/product-proxy";

export default {
  name: "ProductCard",
  props: {
    productData: Object,
  },
  mixins: [imageProxy, productProxy, dateProxy],
  methods: {
    routeToProduct(route) {
      return this.$router.push(route).catch(() => {});
    },
  },
};
</script>
