<template>
  <v-dialog v-model="activeModal" width="800">
    <v-card>
      <v-card-title class="text-h5 white--text primary">
        {{ title }}
      </v-card-title>

      <div class="pa-8">
        <UserForm
          v-if="user"
          :id="user.id"
          :name="user.name"
          :email="user.email"
          :isAdmin="user.is_admin"
          :elementId="'edit'"
          :createForm="false"
          @onSubmitForm="onSubmitForm"
        />
      </div>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" text @click="activeModal = false">
          Abbrechen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import UserForm from "@/components/molecules/UserForm.vue";

export default {
  name: "UserModalBox",
  components: {
    UserForm,
  },
  props: {
    active: Boolean,
    title: String,
    user: Object,
  },
  computed: {
    activeModal: {
      get() {
        return this.active;
      },
      set() {
        this.$emit("onClickAbort", {});
      },
    },
  },
  methods: {
    onSubmitForm(data) {
      this.$emit("onSubmitForm", data);
      this.$emit("onClickAbort", {});
    },
  },
};
</script>