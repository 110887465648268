import Vue from "vue";
import HelperService from "@/api/helper.service";
import StorageService from "@/api/storage.service";
import VuexHistoryConfig from "./config";

import Router from "@/router/router";

const vuexHistoryStore = {
  saveStoreSnapshot(rootState) {
    if (this.containStates()) {
      const result = this.searchCurrentHistoryState();
      const countState = StorageService.getDataFromStorage(
        VuexHistoryConfig.dataStorage.storage,
        VuexHistoryConfig.dataStorage.storageStoreId
      ).length;
      if (result.length === 0 || countState >= 1) {
        this.initSnapshot(rootState);
      }
    } else {
      this.initSnapshot(rootState);
    }
  },

  getVuexHistoryStore() {
    return StorageService.getDataFromStorage(
      VuexHistoryConfig.dataStorage.storage,
      VuexHistoryConfig.dataStorage.storageStoreId
    );
  },

  restoreCurrentRoute() {
    const result = this.searchCurrentHistoryState();
    if (result.length !== 0) {
      //
    }
  },

  currenHistoryStateIsEqualWithLast() {
    const currentVuexHistoryStore = StorageService.getDataFromStorage(
      VuexHistoryConfig.dataStorage.storage,
      VuexHistoryConfig.dataStorage.storageStoreId
    );
    return (
      currentVuexHistoryStore[currentVuexHistoryStore.length - 1].hash ===
      Router.currentRoute.query.state
    );
  },

  getLastHistoryState() {
    const currentVuexHistoryStore = StorageService.getDataFromStorage(
      VuexHistoryConfig.dataStorage.storage,
      VuexHistoryConfig.dataStorage.storageStoreId
    );
    return currentVuexHistoryStore[currentVuexHistoryStore.length - 1];
  },

  searchCurrentHistoryState() {
    return StorageService.getDataFromStorage(
      VuexHistoryConfig.dataStorage.storage,
      VuexHistoryConfig.dataStorage.storageStoreId
    ).filter(
      (historyState) => historyState.hash === Router.currentRoute.query.state
    );
  },

  initSnapshot(rootState) {
    let date = new Date();
    let hash = window.btoa(date);
    let vuexHistoryStore = [
      {
        id: 1,
        hash: hash,
        timestamp: date,
        vuexStore: rootState,
      },
    ];

    if (
      rootState &&
      StorageService.getDataFromStorage(
        VuexHistoryConfig.dataStorage.storage,
        VuexHistoryConfig.dataStorage.storageStoreId
      )
    ) {
      let currentVuexHistoryStore = StorageService.getDataFromStorage(
        VuexHistoryConfig.dataStorage.storage,
        VuexHistoryConfig.dataStorage.storageStoreId
      );
      if (currentVuexHistoryStore.length > VuexHistoryConfig.resetStoreCount) {
        currentVuexHistoryStore =
          HelperService.removeItemsFromArrayIfIndexSmallerThan(
            currentVuexHistoryStore,
            VuexHistoryConfig.resetStoreCount / 2
          );
      }
      currentVuexHistoryStore.push({
        id: currentVuexHistoryStore[currentVuexHistoryStore.length - 1].id + 1,
        hash: hash,
        timestamp: date,
        vuexStore: rootState,
      });
      vuexHistoryStore = currentVuexHistoryStore;
    }
    vuexHistoryStore =
      HelperService.removeUndefinedItemsFromArray(vuexHistoryStore);
    StorageService.addDataToStorage(
      VuexHistoryConfig.dataStorage.storage,
      VuexHistoryConfig.dataStorage.storageStoreId,
      vuexHistoryStore
    );
    Router.push({
      name: Router.currentRoute.name,
      query: { state: hash },
    }).catch(() => {});
  },

  restoreHistoryState(rootState) {
    return new Promise((resolve, reject) => {
      if (this.containStates()) {
        const currentVuexHistoryStore = StorageService.getDataFromStorage(
          VuexHistoryConfig.dataStorage.storage,
          VuexHistoryConfig.dataStorage.storageStoreId
        );
        const result = this.searchCurrentHistoryState();
        let historyState =
          currentVuexHistoryStore[currentVuexHistoryStore.length - 1];
        if (result.length !== 0) {
          historyState = result[0];
        }
        const emptyMessage = {
          text: "",
          title: "",
          type: "",
        };
        rootState.globalStore.message = emptyMessage;
        historyState.vuexStore.globalStore.message = emptyMessage;
        this.replaceStoreWithHistoryState(rootState, historyState);
        resolve();
      } else {
        reject();
      }
    });
  },

  replaceStoreWithHistoryState(rootState, historyState) {
    Object.keys(rootState).map((state) => {
      Vue.set(rootState, state, historyState.vuexStore[state]);
    });
  },

  containStates() {
    const currentVuexHistoryStore = StorageService.getDataFromStorage(
      VuexHistoryConfig.dataStorage.storage,
      VuexHistoryConfig.dataStorage.storageStoreId
    );
    return currentVuexHistoryStore !== null ? true : false;
  },

  resetHistoryStore() {
    StorageService.clearDataStorage(VuexHistoryConfig.dataStorage.storage);
  },
};

export default vuexHistoryStore;
