import UserService from "@/api/user.service";

const actions = {
  getUser: (id) => {
    return new Promise((resolve, reject) => {
      UserService.getUser(id)
        .then((res) => {
          console.info(res);
          resolve(res);
        })
        .catch(() => {
          reject();
        });
    });
  },
  getAllUsers: ({ dispatch, commit }) => {
    dispatch("globalStore/isLoading", true, { root: true });
    return new Promise((resolve, reject) => {
      UserService.getAllUsers()
        .then((res) => {
          let users = [];
          res.data.forEach((user) => {
            delete user.email_verified_at;
            delete user.created_at;
            delete user.updated_at;
            user.is_admin = user.is_admin === 1 ? true : false;
            /* const createdAt = new Date(user.created_at);
            const updatedAt = new Date(user.updated_at);
            user.created_at = `${createdAt.getDate()}.${createdAt.getMonth() + 1}.${createdAt.getFullYear()}`;
            user.updated_at = `${updatedAt.getDate()}.${updatedAt.getMonth() + 1}.${updatedAt.getFullYear()}`; */
            users.push(user);
          });
          commit("UPDATE_ALL_USERS", users);
          resolve(users);
          dispatch("globalStore/isNotLoading", true, { root: true });
        })
        .catch(() => {
          reject();
          dispatch("globalStore/isNotLoading", true, { root: true });
        });
    });
  },
  createUser: ({ dispatch }, newUser) => {
    return new Promise((resolve, reject) => {
      UserService.createUser(newUser)
        .then((res) => {
          resolve(res);
          dispatch(
            "globalStore/setMessage", {
              title: "Info",
              text: "Der Benutzer wurde erfolgreich angelegt!",
              colorType: "success"
            },
            { root: true }
          );
          dispatch("getAllUsers");
        })
        .catch(() => {
          reject();
          dispatch(
            "globalStore/setMessage", {
              title: "Fehler",
              text: "Der Benutzer konnte nicht angelegt werden!",
              colorType: "error"
            },
            { root: true }
          );
        });
    });
  },
  updateUser: ({ dispatch }, updateUser) => {
    return new Promise((resolve, reject) => {
      UserService.updateUser(updateUser)
        .then((res) => {
          resolve(res);
          dispatch(
            "globalStore/setMessage", {
              title: "Info",
              text: "Der Benutzer wurde erfolgreich bearbeitet!",
              colorType: "success"
            },
            { root: true }
          );
          dispatch("getAllUsers");
        })
        .catch(() => {
          reject();
          dispatch(
            "globalStore/setMessage", {
              title: "Fehler",
              text: "Der Benutzer konnte nicht bearbeitet werden!",
              colorType: "error"
            },
            { root: true }
          );
        });
    });
  },
  deleteUser: ({ dispatch }, deleteUser) => {
    return new Promise((resolve, reject) => {
      UserService.deleteUser(deleteUser)
        .then((res) => {
          resolve(res);
          dispatch(
            "globalStore/setMessage", {
              title: "Info",
              text: "Der Benutzer wurde erfolgreich gelöscht!",
              colorType: "success"
            },
            { root: true }
          );
          dispatch("getAllUsers");
        })
        .catch(() => {
          reject();
          dispatch(
            "globalStore/setMessage", {
              title: "Fehler",
              text: "Der Benutzer konnte nicht gelöscht werden!",
              colorType: "error"
            },
            { root: true }
          );
        });
    });
  },
};

export default actions;
