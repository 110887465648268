<template>
  <v-row>
    <v-container class="mt-12 mb-6">
      <v-row>
        <v-divider class="py-2"></v-divider>
        <v-col cols="12" md="4">
          <v-col cols="12">
            <TopicHeader
              :divider="true"
              :text="'Schnellfilter'"
              :color="'white'"
              :bgColor="'primary'"
              :icon="'mdi-filter-variant'"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-select
              id="main-category-selection"
              v-model="currentCategory"
              item-text="name"
              item-value="id"
              label="Kategorie"
              :items="categories"
            >
            </v-select>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-autocomplete
              id="supplier-selection"
              v-model="currentSupplier"
              item-text="name"
              item-value="id"
              label="Hersteller"
              :items="suppliers"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-divider class="py-2"></v-divider>
            <v-row>
              <v-col
                cols="12"
                v-for="feature in fixedFeatures"
                :key="`${Math.random()}-id-${feature.id}-value-${
                  feature.presentation_value
                }-${Math.random()}`"
              >
                {{ feature.name }}
                <v-checkbox
                  v-for="item in feature.values"
                  v-model="activeCheckBoxes"
                  :value="{
                    id: feature.id,
                    value: item.value,
                    name: item.presentation_value,
                  }"
                  :key="`${Math.random()}-id-${feature.id}-value-${
                    item.presentation_value
                  }-${Math.random()}`"
                  :id="`${Math.random()}-id-${feature.id}-value-${
                    item.presentation_value
                  }-${Math.random()}`"
                >
                  <template v-slot:label>
                    {{ item.presentation_value }}
                  </template>
                </v-checkbox>
              </v-col>
              <v-col v-if="supplier">
                <v-row
                  v-for="feature in features"
                  :key="`${Math.random()}-id-${feature.id}-value-${
                    feature.presentation_value
                  }-${Math.random()}`"
                >
                  <v-col
                    cols="12"
                    v-if="(feature.id === 1766 || feature.id === 8730) && searchForFixedValues(feature.values)"
                  >
                    {{ feature.id === 1766 ? "Modellfarbe" : "" }}
                    {{ feature.id === 8730 ? "Speichergröße" : "" }}
                    <div
                      v-for="item in feature.values"
                      :key="`${Math.random()}-id-${feature.id}-value-${
                        item.presentation_value
                      }-${Math.random()}`"
                    >
                      <v-checkbox
                        v-if="showFixedValue(item.presentation_value)"
                        v-model="activeCheckBoxes"
                        :value="{
                          id: feature.id,
                          value: item.value,
                          name: item.presentation_value,
                        }"
                        :id="`${Math.random()}-id-${feature.id}-value-${
                          item.presentation_value
                        }-${Math.random()}`"
                      >
                        <template v-slot:label>
                          {{ item.presentation_value }}
                        </template>
                      </v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="12" md="8">
          <v-col cols="12">
            <TopicHeader
              :divider="true"
              :text="'Produkte'"
              :color="'white'"
              :bgColor="'primary'"
              :icon="'mdi-format-list-bulleted-square'"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="currentSearchTerm"
              append-icon="mdi-magnify"
              label="Suchbegriff"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pt-0">
            <DataTable
              :headers="productDataTableHeaders"
              :editAction="false"
              :clickAction="true"
              :items="products"
              :searchTerm="currentSearchTerm"
              :itemsPerPage="-1"
              :hideFooter="true"
              @onClickItem="setProduct"
            />
            <v-divider class="mt-6"></v-divider>
          </v-col>
          <v-col cols="12" class="pt-0" v-if="currentProduct">
            <div class="d-flex justify-end mb-4"><v-icon @click="setProduct(null, false)">mdi-close</v-icon></div>
            <v-text-field
              v-model="product.id"
              append-icon="mdi-clipboard-multiple-outline"
              label="HYVE-ID"
              ref="hyveId"
              readonly
              @click="copyText"
              @click:append="copyText"
            ></v-text-field>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import TopicHeader from "@/components/atome/TopicHeader.vue";
import DataTableConfig from "@/config/data-table";

import DataTable from "@/components/atome/DataTable.vue";

export default {
  name: "PlaygroundView",
  data: () => ({
    productCountFilterList: [5, 15, 30],
    restorationState: false,
    productDataTableHeaders: DataTableConfig.headers.products,
    categorySelection: null,
    supplierSelection: null,
    currentSearchTerm: "",
    product: null,
    categories: [
      {
        id: 1893,
        name: "Smartphones",
        parent_id: 8705,
      },
    ],
    fixedFeatures: [
      {
        id: 201102,
        name: "Erscheinungsjahr",
        values: [
          {
            presentation_value: 2022,
            value: 2022,
          },
          {
            presentation_value: 2021,
            value: 2021,
          },
          {
            presentation_value: 2020,
            value: 2020,
          },
          {
            presentation_value: 2019,
            value: 2019,
          },
        ],
      },
    ],
    fixedValues: [
      "64 GB",
      "128 GB",
      "256 GB",
      "512 GB",
      "1000 GB",
      "Schwarz",
      "Weiß",
      "Rot",
    ],
    currentSelectedFeatures: [],
  }),
  components: {
    TopicHeader,
    DataTable,
  },
  computed: {
    ...mapGetters({
      features: "productStore/features",
      suppliers: "categoryStore/suppliers",
      supplier: "categoryStore/supplier",
      mainCategory: "categoryStore/mainCategory",
      products: "productStore/products",
      searchTerm: "productStore/searchTerm",
      selectedFeatures: "productStore/selectedFeatures",
      productCount: "productStore/productCount",
    }),
    currentProductCountFilter: {
      get() {
        return this.productCount;
      },
      set(value) {
        this.selectProductCount(value);
      },
    },
    activeCheckBoxes: {
      get() {
        return this.selectedFeatures;
      },
      set(value) {
        this.currentSelectedFeatures = value || [];
        this.selectFeature(value || []);
      },
    },
    currentCategory: {
      get() {
        return this.mainCategory;
      },
      set(value) {
        this.categorySelection = value;
        this.setMainCategory(this.categories[0].id);
        this.fetchSuppliers(value).then(() => {
          this.appIsNotLoading();
        });
      },
    },
    currentSupplier: {
      get() {
        return this.supplier;
      },
      set(value) {
        this.currentProduct = null;
        this.selectSupplier(value);
      },
    },
    currentProduct: {
      get() {
        return this.product;
      },
      set(value) {
        this.product = value;
      },
    },
  },
  methods: {
    ...mapActions({
      setMainCategory: "categoryStore/setMainCategory",
      setSuppliers: "categoryStore/setSuppliers",
      setProducts: "productStore/setProducts",
      selectSupplier: "categoryStore/selectSupplier",
      selectFeature: "productStore/selectFeature",
      selectProductCount: "productStore/selectProductCount",
      appIsNotLoading: "globalStore/isNotLoading",
      fetchSuppliers: "categoryStore/fetchSuppliers",
      fetchProducts: "productStore/fetchProducts",
    }),
    showFixedValue(value) {
      return this.fixedValues.some((fixedValue) => fixedValue === value);
    },
    searchForFixedValues(features) {
      features.filter((feature) => console.info(this.showFixedValue(feature.presentation_value)));
      return features.some((feature) => this.showFixedValue(feature.presentation_value));
    },
    setProduct(value, scroll = true) {
      this.currentProduct = value;
      if (scroll) {
        window.scrollTo(0, document.body.scrollHeight, "smooth");
      }
    },
    copyText() {
      const textToCopy = this.$refs.hyveId.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    },
  },
  mounted() {
    if (this.categories[0]) {
      this.currentCategory = this.categories[0].id;
    }
  },
};
</script>